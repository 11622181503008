.copyright {
	@extend %flex-row;

	align-items: center;
	justify-content: flex-start;
	flex: 1 1;
	padding: 0 $block-spacing;

	&__item {
		font-size: 12px;
		line-height: 1.5em;
		color: $white;
		font-weight: 700;
		padding: $block-spacing;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		justify-content: center;
	}
}
