@include color {
	z-index: 1;

	&__container {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		gap: calc(14px + #{$block-spacing});
	}

	&__replacement {
		margin: 7px 15px 7px 7px;
		height: 34px;
		width: 34px;
		border-radius: 34px;
		border: 1px solid $border-color;
		box-shadow: none;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;

		&::before {
			display: block;
			content: ' ';
			height: 48px;
			width: 48px;
			border-radius: 48px;
			background-color: $white;
			border: 3px solid $border-color;
			z-index: -1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		i {
			visibility: hidden;
			font-size: 16px;
			color: $white;
			text-shadow: 0 0 1px $black;
		}

		&:hover {
			border: 1px solid $border-color;
			box-shadow: none;

			&::before {
				border-color: $highlight-color;
			}
		}

		&--large {
			margin: 0;
			height: 64px;
			width: 64px;
			border-radius: 64px;

			&::before {
				height: 78px;
				width: 78px;
				border-radius: 78px;
			}
		}
	}

	&--filters .color__replacement {
		margin: 7px;
	}

	&__input:checked + .color__replacement {
		border: 3px solid $border-color;
		box-shadow: none;

		&::before {
			border: 3px solid $highlight-color;
		}

		i {
			visibility: visible;
		}
	}
}
