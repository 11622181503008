.social {
	@extend %flex-row;

	align-items: center;
	justify-content: flex-end;
	width: 25%;

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		width: 32px;
		border-radius: 32px;
		font-size: 18px;
		background-color: $black;
		color: $white;
		margin: $block-spacing;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		justify-content: center;
		order: 2;
	}
}
