@include size {
	margin: 0 $block-spacing $block-spacing 0;

	&__replacement {
		min-width: 50px;
		color: $font-color;
		font-size: 14px;
		font-weight: 400;
		padding: 14px $block-spacing;
		border: 1px solid $border-color;
		border-radius: 50px;
		background-color: $white;
		font-family: $primary-font-family;
		margin: 0;
	}

	&--filters {
		flex: 1;
		display: flex;
		margin: 0;

		.size__replacement {
			flex: 1;
			white-space: nowrap;
		}
	}

	&__input:checked + .size__replacement {
		color: $font-color;
		background-color: $white;
		border-color: $highlight-color;
		font-weight: 700;
	}
}
