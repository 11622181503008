@include header {
	background-color: $background-color;
	padding: 0;
	position: relative;
	border: 0;

	&__placeholder {
		height: 170px;

		&--shoppingcart {
			height: 120px;
		}
	}

	&__fixed {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
		background-color: $background-color;
	}

	&__top {
		background-color: $background-color-2;
		padding: 0 5%;

		.header__container {
			max-width: $grid-width;
		}
	}

	&__top,
	&__mid {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		width: 100%;
	}

	&__mid {
		background-color: $white;
	}

	&__container {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&--scrolled {
		.header__fixed {
			top: -40px;
		}

		.header__placeholder {
			height: 66px;
		}
	}

	@include responsive(('desktop')) {
		&__placeholder--shoppingcart {
			height: 170px;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__fixed {
			min-height: unset;
		}

		&__placeholder {
			height: 170px;

			&--shoppingcart {
				height: 170px;
			}
		}

		&__top {
			padding: 0;

			.header__container {
				padding: 0;
			}
		}

		&__mid {
			display: flex;
			align-items: stretch;
		}

		&__top,
		&__mid {
			padding: 0;
		}

		&__container {
			flex: 1;
			display: flex;
			align-items: stretch;
		}

		.language {
			display: none;
		}

		&--scrolled {
			.header__placeholder {
				height: 62px;
			}

			.header__fixed {
				top: -32px;
			}

			.nav__mobile-dropdown {
				top: 62px;
			}

			.search {
				top: 62px;
			}

			.search__dropdown {
				top: calc(62px + 73px);
			}
		}
	}
}
