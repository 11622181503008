.specialist {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;

	&__left {
		padding: $block-spacing;
		padding-right: 4%;

		@include responsive(('tablet-landscape')) {
			width: 100%;
		}
	}

	&__center {
		border-right: 1px solid $border-color;
		padding: $block-spacing;
		padding-right: 4%;

		@include responsive(('tablet-landscape')) {
			width: 50%;
		}
	}

	&__right {
		padding: $block-spacing;
		padding-left: 4%;

		@include responsive(('tablet-landscape')) {
			width: 50%;
		}
	}

	&__left,
	&__center,
	&__right {
		flex: 1 1 auto;
		width: 33.33%;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			width: 100%;
			padding: $block-spacing;
			border: 0;
		}
	}

	&__stats {
		font-size: 16px;
		color: $font-color-2;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		padding: $block-spacing 0;

		i {
			color: $brand-color;
		}
	}

	.fa-circle-minus {
		color: $font-color-3;
	}
}
