.accordion {
	&__toggle {
		cursor: pointer;
		position: relative;

		&--flex {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: $block-spacing;
		}
	}

	&__icon-close,
	&__icon-open {
		@extend %position-right;

		&--unset {
			position: unset;
			transform: none;
			right: auto;
			top: auto;
		}
	}

	&__icon-close,
	&__close {
		display: block;
	}

	&__icon-open,
	&__open {
		display: none;
	}

	&__container {
		overflow-y: hidden;
		transition: height 0.3s ease-in-out 0s;
	}

	&--border {
		border: 1px solid $border-color;
		border-bottom: 0;
		padding: $block-spacing;

		&:last-child {
			border-bottom: 1px solid $border-color;
		}
	}

	&--hidden,
	&__sub--hidden {
		.accordion__container {
			height: 0;
		}

		.accordion__icon-close,
		.accordion__close {
			display: none;
		}

		.accordion__icon-open,
		.accordion__open {
			display: block;
		}
	}

	&__open--show-more,
	&__close--show-less {
		padding: 0 20px 0 0;
		color: $brand-color;
		text-decoration: underline;
	}

	&__sub {
		padding: $block-spacing 0;
	}
}
