.product-usp {
	display: flex;
	flex-flow: row nowrap;
	color: $font-color;
	font-size: 12px;
	line-height: normal;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	margin-bottom: $block-spacing * 3;
	padding-top: $block-spacing * 3;
	padding-bottom: $block-spacing * 3;
	gap: $block-spacing * 2;

	div {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;
	}

	span {
		display: block;
		text-align: center;
	}

	i {
		font-size: 24px;
		margin-bottom: $block-spacing * 2;
	}
}
