.scroll {
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	white-space: nowrap;
	margin-bottom: $block-spacing * 4;

	.table {
		margin-bottom: 0;
	}

	&--steps {
		max-width: calc(100vw - #{$block-spacing * 6});
	}
}
