@mixin text {
	@at-root .text {
		position: relative;
		width: 100%;
		color: $font-color;
		font-size: 16px;
		line-height: 1.5em;
		font-family: $primary-font-family;

		strong,
		b {
			font-weight: bold;
		}

		em {
			font-style: italic;
		}

		ol {
			@extend %ordered-list;
		}

		ul {
			@extend %unordered-list;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}

		iframe {
			max-width: 100%;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $secondary-font-family;
			line-height: normal;
		}

		h1 {
			font-size: 56px;
			line-height: 72px;
			letter-spacing: -1.12px;
		}

		h2 {
			font-size: 40px;
			letter-spacing: -0.8px;
		}

		h3 {
			font-size: 32px;
			letter-spacing: normal;
		}

		h4 {
			font-size: 24px;
			letter-spacing: -0.48px;
		}

		h5 {
			font-size: 20px;
			letter-spacing: -0.4px;
		}

		h6 {
			font-size: 18px;
			letter-spacing: normal;
		}

		td,
		th {
			text-align: left;
		}

		a {
			text-decoration: none;
			color: $highlight-color;

			&:hover {
				text-decoration: underline;
			}
		}

		u {
			text-decoration: underline;
		}

		&--download {
			ul {
				list-style: none;
			}

			li {
				padding: $block-spacing 0;
			}

			i {
				margin-right: $block-spacing;
			}
		}

		&--numbers {
			padding: $block-spacing * 4 0;

			ul {
				list-style: none;
				display: flex;
				flex-flow: row wrap;
				gap: $block-spacing * 2;
			}

			li {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				gap: $block-spacing * 2;
			}

			span {
				flex: 1 1 auto;
			}
		}

		&__number {
			color: $brand-color;
			font-size: 32px;
			font-family: $secondary-font-family;
			background-color: $background-color-6;
			height: 48px;
			width: 48px;
			line-height: 38px;
			text-align: center;
			border-radius: 48px;
			flex-shrink: 0;
		}

		&__usp {
			color: $font-color;
			padding-bottom: $block-spacing * 2;

			ul {
				list-style: none;
				margin-top: $block-spacing * 2;

				i {
					color: $brand-color;
					font-size: 32px;
					min-width: 40px;
					margin-top: $block-spacing;
				}

				li {
					margin-bottom: $block-spacing * 2;
					display: flex;
					align-items: top;
					gap: $block-spacing * 2;
				}
			}

			ol {
				list-style: none;
				padding-bottom: $block-spacing;

				i {
					color: $brand-color;
					font-size: 18px;
				}

				li {
					display: flex;
					align-items: center;
					gap: $block-spacing;
					margin-bottom: $block-spacing * 2;
				}
			}
		}

		&__buttons {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: $block-spacing * 2;

			&--grow {
				.button {
					flex-grow: 1;
				}
			}

			&--showroom-banner {
				padding-left: 56px;
			}

			@include responsive(('mobile')) {
				.button {
					flex-grow: 1;
				}
			}
		}

		&--nav {
			padding: $block-spacing * 2;
			font-size: 14px;
			color: $font-color-2;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: 20px;
				letter-spacing: -0.4px;
				font-weight: bold;
				font-family: $secondary-font-family;
				color: $font-color;
			}

			ul {
				list-style: disc outside;
				padding-left: 18px;
			}

			ol {
				list-style: auto outside;
				padding-left: 18px;
			}
		}

		&--2 {
			color: $font-color-2;
		}

		&--align-left {
			text-align: left;
		}

		&--align-right {
			text-align: right;
		}

		&--align-center {
			text-align: center;
		}

		&--bold {
			font-weight: bold;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--big {
			font-size: 24px;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				font-size: 20px;
			}
		}

		&--small {
			font-size: 14px;
		}

		&--smaller {
			font-size: 12px;
		}

		&--spacing {
			margin-top: $block-spacing;
			margin-bottom: $block-spacing;
		}

		&--spacing-2x {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-3x {
			margin-top: $block-spacing * 3;
			margin-bottom: $block-spacing * 3;
		}

		&--spacing-4x {
			margin-top: $block-spacing * 4;
			margin-bottom: $block-spacing * 4;
		}

		&--spacing-top {
			margin-top: $block-spacing;
		}

		&--spacing-top-2x {
			margin-top: $block-spacing * 2;
		}

		&--spacing-top-3x {
			margin-top: $block-spacing * 3;
		}

		&--spacing-top-4x {
			margin-top: $block-spacing * 3;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing;
		}

		&--spacing-bottom-2x {
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-bottom-3x {
			margin-bottom: $block-spacing * 3;
		}

		&--spacing-bottom-4x {
			margin-bottom: $block-spacing * 4;
		}

		&--message {
			width: calc(100% - #{$block-spacing * 2});
			margin: $block-spacing;
			padding: $block-spacing * 2;
			background-color: $background-color;
			border-radius: 4px;
		}

		&--highlighted,
		&--brand-color {
			color: $brand-color;
		}

		&--article {
			table {
				margin: 16px 0;
				width: 100%;
				background: $white;
				border-radius: 8px;
				overflow: hidden;
			}

			table tr {
				border-bottom: 1px solid $background-color-3;

				&:last-child {
					border-bottom: 0;
				}
			}

			table td,
			table th {
				padding: 4px 8px;
			}

			table tr:nth-child(1) td,
			table th {
				background-color: $background-color;
				font-weight: 700;
			}

			table:not(table.table--2-cells) th,
			table:not(table.table--2-cells) td {
				padding: $block-spacing * 2;
			}

			table tr:nth-child(2n-1):not(:last-child):not(:first-child) td {
				background-color: $background-color-3;
			}

			p {
				margin-bottom: $block-spacing * 2;
			}

			h2,
			h3,
			h4 {
				margin-top: $block-spacing * 3;
				margin-bottom: $block-spacing;
			}

			ul {
				list-style: disc outside;
				padding-left: 18px;
				margin-bottom: $block-spacing * 2;
			}

			ol {
				list-style: auto outside;
				padding-left: 18px;
				margin-bottom: $block-spacing * 2;
			}

			em {
				font-style: italic;
			}

			u {
				text-decoration: underline;
			}
		}

		&--choices {
			font-size: 14px;

			ol,
			ul {
				list-style: none;
				padding-bottom: $block-spacing;

				i {
					color: $brand-color;
					font-size: 18px;
					margin-right: $block-spacing;
				}

				li {
					margin-bottom: $block-spacing * 2;
				}

				span {
					color: $brand-color;
					font-weight: 700;
				}

				strong {
					font-weight: 700;
				}
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			&--spacing {
				margin-top: $block-spacing * 2;
				margin-bottom: $block-spacing * 2;
			}

			&--spacing-top {
				margin-top: $block-spacing * 2;
			}

			&--spacing-bottom {
				margin-bottom: $block-spacing * 2;
			}
		}

		&--error {
			font-size: 14px;
			color: $error-color;
			padding: 0.25rem 0;
		}

		&--hidden {
			display: none;
		}

		@content;
	}
}
