@include search {
	flex-grow: 1;

	&__icon {
		font-size: 16px;
		position: absolute;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
		z-index: 9;
	}

	&__input {
		font-size: 12px;
		width: 100%;
		background-color: $white;
		border-radius: 32px;
		padding: $block-spacing * 2;
		padding-right: 40px;
		z-index: 9;
		position: relative;
	}

	&__overlay {
		position: fixed;
		bottom: 0;
		right: 0;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba($black, 0.5);
		z-index: 9;
		display: none;

		&--active {
			display: block;

			& ~ .search__input {
				border-radius: 0;
			}
		}
	}

	&__dropdown {
		border-radius: 0;
		padding: 0;
		width: 1030px;
		height: 800px;
		left: 0;
		right: auto;
		top: calc(100% - 1px);
		overflow-y: auto;
		max-height: calc(100vh - 121px);
		border: 1px solid $border-color;
		z-index: 11;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			height: auto;
		}

		&--active {
			display: block;
		}
	}

	&__dropdown-container {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		height: 100%;
	}

	&__dropdown-left {
		height: 100%;
		overflow-y: auto;
		background-color: $background-color-6;
		padding: $block-spacing * 3 $block-spacing * 4 $block-spacing * 4;
		width: 335px;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			height: auto;
		}

		.text a {
			color: inherit;
		}
	}

	&__dropdown-right {
		background-color: $white;
		flex: 1;
		display: flex;
		flex-flow: column wrap;
	}

	&__dropdown-top {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		gap: $block-spacing;
		padding: $block-spacing * 2;

		.title,
		.text {
			display: inline-block;
			width: auto;
		}
	}

	&__dropdown-center {
		display: flex;
		flex: 1;
		flex-flow: column wrap;
		padding: $block-spacing * 2;
		gap: $block-spacing;
	}

	&__dropdown-bottom {
		display: flex;
		align-items: center;
		gap: $block-spacing;
		padding: $block-spacing * 2;
	}

	&__dropdown-close {
		width: 100%;
		display: none;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
			display: inline-block;
		}
	}

	&__item {
		font-family: $secondary-font-family;
		color: $black;
		font-size: 16px;
		font-weight: bold;
		display: block;
		padding: $block-spacing * 1.5 0;

		&--title {
			font-size: 18px;
		}

		&--sub {
			font-weight: normal;
			font-family: $primary-font-family;
		}
	}

	&__link {
		display: block;
		padding: $block-spacing * 2 0;
	}

	&__product {
		display: flex;
		align-items: center;
		width: 100%;

		i {
			font-size: 48px;
			color: $brand-color;
		}
	}

	&__product-image {
		width: 95px;

		img {
			display: block;
			max-width: 100%;
		}
	}

	&__product-info {
		flex: 1;
		display: flex;
		flex-flow: row wrap;
		padding-left: $block-spacing * 2;
		align-items: center;
		justify-content: space-between;
	}

	&__product-container {
		flex: 1;
	}

	&__product-name {
		font-family: $secondary-font-family;
		color: $brand-color;
		font-size: 18px;
		font-weight: 700;
	}

	&__product-summary {
		color: $font-color;
		font-size: 14px;
		font-weight: 400;
	}

	&__product-price {
		display: flex;
		flex-flow: column wrap;
		line-height: normal;
		padding-right: $block-spacing * 4;
	}

	&__product-price-highlight {
		font-size: 16px;
		color: $font-color;
		font-weight: 700;
	}

	&__product-price-advice {
		font-size: 12px;
		color: $font-color-3;
		font-weight: 400;
		text-decoration: line-through;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
		position: fixed;
		top: 120px;
		height: 50px;
		left: 0;
		right: 0;
		width: 100%;
		background-color: $background-color-3;
		z-index: 1;
		padding: $block-spacing / 2;
		display: none;

		&__input {
			width: 100%;
			margin: 0;
			padding: 12px 16px;
			padding-right: 40px;
			border-radius: 0;
		}

		&__overlay {
			height: calc(100vh - 94px);
			display: none;

			&--active {
				display: none;
			}
		}

		&__dropdown {
			width: 100%;
			border-radius: 0;
			position: fixed;
			top: 165px;
			right: 0;
			bottom: 0;
			left: 0;
			max-height: 100vh;
			border: 0;
			background-color: $background-color-3;
			padding: $block-spacing / 2;
		}

		&__dropdown-left {
			background-color: $background-color-3;
		}

		&--active {
			display: block;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&__dropdown-left,
		&__dropdown-right {
			width: 100%;
		}

		&__dropdown-left {
			order: 2;
			padding: $block-spacing * 2;
		}

		&__dropdown-right {
			order: 1;
		}
	}
}
