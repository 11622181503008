@mixin usp {
	@at-root .usp {
		width: 100%;
		margin: 0;
		flex: 1;

		&__container {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__review {
			color: $white;
			font-size: 12px;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			a {
				font-weight: normal;
				text-decoration: underline;
			}
		}

		&__review-score {
			min-width: 30px;
			min-height: 30px;
			background-image: url('/_zorgtotaal/img/icons/kiyoh.png');
			background-repeat: no-repeat;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 8px;
			margin-right: $block-spacing;
			background-size: cover;
		}

		&__items {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex: 1;
		}

		&__item {
			color: $font-color;
			position: relative;
			display: inline-block;
			vertical-align: middle;
			font-size: 12px;
			padding: $block-spacing;

			strong {
				font-weight: 700;
			}
		}

		&__icon {
			color: $brand-color;
			margin-right: $block-spacing;
		}

		&--product,
		&--article {
			margin: $block-spacing * 2 0;

			.usp__item {
				color: $font-color-2;
				font-size: 16px;
				display: block;
				padding: $block-spacing;
				padding-left: 26px;
				text-align: left;

				i {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					margin-right: $block-spacing;
					font-size: 18px;
				}
			}
		}

		&--article {
			margin-top: 0;
		}

		&--spacing-bottom-3x {
			margin-bottom: $block-spacing * 3;
		}

		&--spacing-bottom-4x {
			margin-bottom: $block-spacing * 4;
		}

		&--steps {
			margin-top: $block-spacing * 2;
			flex: 0;

			.usp__item {
				color: $font-color-2;
				font-size: 14px;
				display: block;
				padding: $block-spacing;
				padding-left: 26px;
				text-align: left;

				i {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					margin-right: $block-spacing;
					font-size: 18px;
				}
			}
		}

		&--header {
			padding: 5px 0 5px $block-spacing;

			.usp__item {
				color: $white;
				font-weight: 700;
			}
		}

		&--footer {
			margin-top: $block-spacing * 3;

			.usp__review {
				color: $font-color;
			}

			.usp__review-score {
				min-width: 40px;
				min-height: 40px;
				font-size: 12px;
			}
		}

		&--hero-banner {
			padding: $block-spacing * 3 0 $block-spacing * 4 0;

			.usp__item {
				font-size: 16px;
				color: $font-color-2;
				padding: $block-spacing $block-spacing * 2 $block-spacing 0;
			}

			.usp__icon {
				font-size: 18px;
			}
		}

		&--category {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			padding: $block-spacing * 2 $block-spacing;
			width: 100%;
			flex: auto;

			&::before,
			&::after {
				content: ' ';
				display: block;
				flex: auto;
				height: 1px;
				background-color: $border-color;
			}

			&::before {
				margin-right: $block-spacing;
			}

			&::after {
				margin-left: $block-spacing;
			}

			.usp__item {
				font-weight: 700;
			}
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing;
		}

		&--mobile {
			display: none;
		}

		@include responsive(('desktop')) {
			&--desktop {
				.usp__item {
					font-size: 12px;
				}
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			&__review {
				justify-content: center;
				min-height: 40px;
			}

			&--desktop {
				display: none;
			}

			&--mobile {
				display: block;

				.usp__item {
					width: 100%;
					text-align: center;
					padding: 0;
				}
			}

			&--hero-banner {
				padding: $block-spacing 0 $block-spacing * 2;

				.usp__item {
					width: 100%;
				}
			}

			&--header {
				padding: 0;

				.usp__item {
					min-height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 $block-spacing;
				}

				.carousel__slide {
					padding: 0;
				}
			}

			&--footer {
				.usp__review {
					justify-content: flex-start;
				}
			}
		}

		@include responsive(('mobile', 'mobile-landscape')) {
			&__item {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&--category {
				&::after,
				&::before {
					display: none;
				}
			}

			&--product,
			&--article {
				.usp__item {
					white-space: normal;
				}
			}
		}

		@content;
	}
}
