.brand {
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
	border: 1px solid $border-color;
	border-radius: 16px;
	background-color: $white;
	position: relative;
	margin-bottom: 24px;

	&__image {
		width: calc(100% - #{$block-spacing * 6});
		display: block;
		margin: $block-spacing * 3 0 0;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 46%;
		}
	}

	&__name {
		color: $font-color;
		background-color: $white;
		font-family: $primary-font-family;
		font-size: 12px;
		border: 1px solid $border-color;
		border-radius: 50px;
		padding: 15px $block-spacing * 3;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: $block-spacing;
		transform: translateY(50%);

		i {
			font-size: 16px;
		}
	}

	&__info {
		display: none;
	}
}
