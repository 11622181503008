@include footer {
	align-items: center;
	justify-content: center;
	padding: 0;
	border: 0;
	line-height: 1.5em;
	margin: 0;
	box-shadow: 0 -4px 32px 0 rgb(0 0 0 / 5%);

	&__top {
		width: 100%;
		background-image: url('/_zorgtotaal/img/footer.png');
		background-position: bottom right;
		background-repeat: no-repeat;
		background-color: $background-color-3;
		display: flex;
		flex-wrap: wrap;
	}

	&__bot {
		@extend %flex-row;

		align-items: center;
		background-color: $background-color-2;
		width: 100%;
		min-height: 40px;
	}

	&__mid,
	&__bot {
		padding-left: 5%;
		padding-right: 5%;
	}

	&__left,
	&__right {
		padding-top: $vertical-spacing;
		padding-bottom: $vertical-spacing;
		width: 50%;
		display: flex;
	}

	&__left {
		background-color: $white;
		align-items: flex-start;
		justify-content: flex-end;
	}

	&__right {
		background-color: transparent;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__container {
		display: flex;
		width: $grid-width / 2;
		max-width: 90%;
		flex-wrap: wrap;
	}

	@include responsive(('desktop')) {
		&__top {
			background-size: 220px;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__left,
		&__right {
			padding-top: $vertical-spacing-mobile;
			padding-bottom: $vertical-spacing-mobile;
			width: 100%;
			justify-content: center;
		}

		&__container {
			width: $grid-width;
		}

		&__bot {
			padding: 5px 0;
		}
	}

	@include responsive(('mobile-landscape')) {
		&__top {
			background-size: 150px;
		}
	}

	@include responsive(('mobile')) {
		&__top {
			background: transparent;
		}
	}
}
