@include nav {
	justify-content: center;
	align-items: stretch;
	padding: 0;

	&__mid {
		width: 100%;
		flex: 1 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 5%;
		min-height: 80px;
	}

	&__bottom {
		width: 100%;
		flex: 1 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 5%;
		background-color: $background-color;
	}

	&__opening-hours {
		position: relative;
		font-size: 12px;
		line-height: 1.5em;
		padding-left: 28px;
		display: flex;
		flex-direction: column;

		&--opened {
			strong {
				color: $brand-color;
			}

			&::after {
				background-color: $brand-color;
				width: 12px;
				height: 12px;
				border-radius: 12px;
				display: block;
				content: ' ';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
			}
		}

		&--appointment {
			strong {
				color: $warning-color;
			}

			&::after {
				background-color: $warning-color;
				width: 12px;
				height: 12px;
				border-radius: 12px;
				display: block;
				content: ' ';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
			}
		}

		&--closed {
			strong {
				color: $error-color;
			}

			&::after {
				background-color: $error-color;
				width: 12px;
				height: 12px;
				border-radius: 12px;
				display: block;
				content: ' ';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
			}
		}

		strong {
			font-weight: 800;
		}

		// &::after {
		// 	width: 12px;
		// 	height: 12px;
		// 	border-radius: 12px;
		// 	display: block;
		// 	content: ' ';
		// 	// background-color: $brand-color;
		// 	position: absolute;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// 	left: 0;
		// }
	}

	&__container {
		flex: 1 auto;
		width: 100%;
		max-width: $grid-width;
		align-items: center;
		justify-content: space-between;
		flex-flow: row nowrap;

		&--stretch {
			align-items: stretch;

			.nav__item,
			.nav__link {
				height: 100%;
			}
		}
	}

	&__link {
		position: relative;
		display: flex;
		align-items: center;
		padding: $block-spacing * 2 0;
	}

	&__item {
		font-family: $secondary-font-family;
		font-size: 14px;
		font-weight: 500;
		color: $font-color;
		padding: 0;

		&:hover {
			text-decoration: none;

			.nav__sub {
				display: flex;
			}
		}

		&--active .nav__link {
			font-weight: 700;
			color: $brand-color;
		}

		&--home {
			font-size: 18px;
		}

		&--shop {
			border: 1px solid $border-color;
			border-radius: 32px;
			padding: $block-spacing * 2;
			font-family: $primary-font-family;
			margin-left: $block-spacing * 2;

			p {
				display: inline;
			}

			&:last-child {
				margin-right: $block-spacing;
			}
		}

		&--cs {
			font-family: $primary-font-family;
			font-weight: 700;
			font-size: 12px;
			position: relative;

			.nav__link {
				padding: $block-spacing * 2;
			}

			&::after {
				content: ' ';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				display: block;
				background-color: $border-color-2;
				width: 1px;
				height: 16px;
			}

			i {
				margin-right: $block-spacing;
			}

			&:last-child {
				.nav__link {
					padding-right: $block-spacing;
				}

				&::after {
					display: none;
				}
			}
		}

		&:hover .nav__link {
			color: $brand-color;
		}

		&--mobile {
			display: none;
		}

		&--relative {
			position: relative;
		}
	}

	&__icon {
		font-size: 16px;
		margin: 0 $block-spacing 0 0;
		cursor: pointer;
	}

	&__count {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: -8px;
		right: -8px;
		min-width: 20px;
		height: 20px;
		border-radius: 20px;
		color: $white;
		font-size: 12px;
		background-color: $highlight-color-2;
	}

	&__left {
		flex: 1 auto;
		display: flex;
		align-items: center;

		.nav__item {
			padding-left: $block-spacing * 2;
			padding-right: $block-spacing * 2;
		}
	}

	&__right {
		flex: 1 auto;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__sub-container {
		width: $grid-width;
		margin: 0 auto;
		max-width: 100%;
		display: flex;
		padding: $block-spacing * 2 0;
	}

	&__sub-items {
		width: 25%;
		display: flex;
		align-items: flex-start;
		flex-flow: column wrap;
		padding-top: $block-spacing * 3;
		padding-bottom: $block-spacing * 3;
		padding-left: $block-spacing;

		&--categories {
			border-right: 1px solid $border-color;
			padding-left: 0;
			padding-right: $block-spacing;
		}

		&--content {
			width: 50%;
			padding: $block-spacing * 3 8%;
		}

		&--border-left {
			border-left: 1px solid $border-color;
		}

		&:only-child {
			border: 0;
		}
	}

	&__sub-item {
		font-size: 14px;
		line-height: 1.5em;
		display: block;
		width: 100%;
		color: $font-color;
		padding: $block-spacing * 2 $block-spacing * 2 $block-spacing * 2 $block-spacing;
		position: relative;
		font-family: $primary-font-family;

		&--heading {
			font-size: 20px;
			letter-spacing: -0.4px;
			font-weight: bold;
			font-family: $secondary-font-family;
		}

		&:hover,
		&--active {
			color: $brand-color;
		}
	}

	&__sub-icon {
		position: absolute;
		top: 50%;
		right: $block-spacing;
		transform: translateY(-50%);
	}

	&__sub-inner {
		width: 100%;
		display: none;
		flex-flow: column wrap;

		&--active {
			display: flex;
		}
	}

	&__sub {
		background-color: $white;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 9;
		display: none;
		align-items: center;
		justify-content: center;
		padding: 0 5%;
		border-bottom: 1px solid $border-color;
		box-shadow: 0 0 32px 0 rgb(0 0 0 / 10%);

		&--small {
			width: 328px;
			right: auto;
			left: -$block-spacing * 4;
			padding: 0;

			.nav__sub-items {
				width: 100%;
				padding-left: $block-spacing * 3;
				padding-right: $block-spacing * 3;
				max-height: calc(100vh - 170px);
				overflow-y: auto;
			}
		}
	}

	&__mobile-dropdown {
		display: none;
		position: fixed;
		z-index: 9;
		background-color: $background-color;
		top: 170px;
		left: 0;
		right: 0;
		bottom: 0;

		&--active {
			display: block;
		}
	}

	&__mobile-container {
		height: 100%;
		display: flex;
		flex-direction: column;

		&--hidden {
			display: none;
		}
	}

	&__mobile-scroll {
		flex: 1;
		overflow-y: auto;
	}

	&__mobile-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $block-spacing * 3 5%;
		position: relative;

		&::after {
			content: ' ';
			display: block;
			position: absolute;
			width: calc(90% - #{$block-spacing * 2});
			height: 2px;
			background-color: $border-color;
			top: 100%;
			left: calc(5% + #{$block-spacing});
		}
	}

	&__mobile-title {
		font-size: 18px;
		color: $font-color;
		font-weight: bold;
		font-family: $secondary-font-family;
		padding-left: $block-spacing;
	}

	&__mobile-link {
		padding: $block-spacing * 3 $block-spacing * 4;
		padding-left: $block-spacing;
		flex: 1;
	}

	&__mobile-icon {
		padding: $block-spacing * 3 $block-spacing * 4;
		padding-right: $block-spacing;
		font-size: 18px;
	}

	&__mobile-item {
		font-family: $secondary-font-family;
		font-size: 14px;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $font-color;
		padding: 0 5%;

		&:first-child {
			margin-top: $block-spacing * 3;
		}

		&--active {
			font-weight: bold;
		}

		&--small {
			font-size: 12px;
			font-weight: 700;
		}
	}

	&__mobile-toggle,
	&__mobile-close,
	&__mobile-back {
		display: flex;
		align-items: center;
		font-size: 24px;
		color: $font-color;
		padding-left: $block-spacing;
		height: 100%;
		cursor: pointer;
		padding-right: $block-spacing;

		span {
			margin-left: $block-spacing;
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	&__mobile-toggle {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		span {
			font-weight: 700;
			font-size: 8px;
			margin: 0;
		}
	}

	&__mobile {
		display: none;
		align-items: center;
		justify-content: flex-start;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
		padding: 0 5%;

		&__opening-hours {
			position: fixed;
			left: 0;
			right: 0;
			top: 120px;
			background-color: $background-color-3;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: $block-spacing * 2 5%;
			white-space: nowrap;
			overflow-x: hidden;

			div {
				padding: 0 $block-spacing;

				&:first-child {
					padding-left: calc(12px + #{$block-spacing * 2});
				}
			}

			&::after {
				left: calc(5% + #{$block-spacing});
			}
		}

		&__left {
			flex: 1 1;
			justify-content: space-between;

			.nav__item {
				padding-left: $block-spacing;
				padding-right: $block-spacing;
			}
		}

		&__right {
			flex: 0 0;
		}

		&__bottom {
			display: none;
		}

		&__mid {
			width: auto;
			padding: 0 $block-spacing;
		}

		&__mobile {
			display: flex;
		}

		&__item {
			&--shop {
				width: 50px;
				height: 50px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: $block-spacing;

				p {
					display: none;
				}

				.nav__icon {
					margin: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}

			&--desktop {
				display: none;
			}

			&--mobile {
				display: block;
			}
		}

		&__open-search {
			cursor: pointer;
			min-width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid $border-color;
			border-radius: 50px;
			font-size: 14px;
			color: $font-color;
			font-weight: 500;

			.nav__icon {
				margin: 0;
			}
		}
	}

	@include responsive(('mobile')) {
		&__opening-hours {
			padding: 18px 5%;
			font-size: 10px;

			span {
				display: none;
			}
		}
	}
}
