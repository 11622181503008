body {
	text-size-adjust: 100%;
	background-color: $body-background-color;
}

.clear {
	clear: both;
}

/* apply a natural box layout model to all elements */
*,
*::before,
*::after {
	box-sizing: border-box;
}

// .debug {
// 	pre.xdebug-var-dump {
// 		z-index: 9999;
// 		position: relative;
// 		background: white;
// 		padding: 0 18px;
// 	}
// }
