@include filters {
	color: $font-color;
	position: relative;
	padding-bottom: $block-spacing * 2;

	&__container {
		@extend %flex-row;

		padding: $block-spacing * 1.5 0;
		gap: $block-spacing * 1.5;

		&--colors {
			gap: $block-spacing;
		}
	}

	&__item {
		padding: $block-spacing 0;
		font-size: 16px;
		font-family: $secondary-font-family;

		&:hover {
			color: inherit;
		}

		&--heading {
			border-bottom: 0;
			margin-top: $block-spacing * 2;
			padding: 0 20px 0 0;
			font-size: 20px;
			font-weight: 700;
		}

		&--show-more {
			padding: 0 20px 0 0;
			color: $brand-color;
			text-decoration: underline;
		}

		&--active {
			color: $active-color;
		}

		a {
			&:hover {
				color: $highlight-color;
			}
		}
	}

	&__subitem {
		@extend %paragraph;

		width: 100%;
		font-family: $primary-font-family;
		padding: $block-spacing 0;
		font-size: 14px;

		&--sub {
			margin-left: $block-spacing * 2;
		}

		&--active {
			color: $active-color;
		}
	}

	strong {
		font-weight: bold;
	}

	.button {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.accordion__icon-open,
	.accordion__icon-close {
		i {
			font-size: 20px;
		}
	}

	@include responsive(('tablet-landscape')) {
		padding-right: $block-spacing * 2;
		padding-left: $block-spacing * 2;
	}
}
