.choices {
	/* Containers */
	&__container {
		background: $white;
		display: flex;
		flex-flow: column;
	}

	&__top {
		background-color: $white;
		padding: $block-spacing * 3 $block-spacing * 4;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
	}

	&__top-left {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column wrap;
		justify-content: flex-start;

		.choice_text,
		.choice_description {
			&--hidden {
				display: none;
			}
		}
	}

	&__top-right {
		flex: 1 1 auto;
	}

	&__center {
		display: flex;
		flex-flow: row wrap;
		flex: 1;
		height: 100%;
		overflow-y: auto;
		padding: 0 $block-spacing * 4 $block-spacing * 4 $block-spacing * 4;
		position: relative;
	}

	&__bottom {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		gap: $block-spacing;
		justify-content: space-between;
		border-top: 1px solid $border-color;
		background-color: $background-color-4;
		padding: $block-spacing * 3 $block-spacing * 4;
	}

	&__bottom-left {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;
		gap: $block-spacing;
		font-size: 12px;
		color: $font-color;
		flex: 1 1 auto;

		span {
			padding: $block-spacing / 2 0;

			&:last-child {
				width: 100%;
				display: block;
			}
		}

		.products_count {
			.product_count {
				&--hidden {
					display: none;
				}
			}
		}
	}

	&__bottom-right {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-end;
		gap: $block-spacing;
		flex: 1 1 auto;
	}

	/* Top */
	&__close {
		display: flex;
		flex-flow: column wrap;
		align-items: flex-end;
		cursor: pointer;
	}

	&__close-heading {
		color: $brand-color;
		font-size: 14px;
		text-decoration: underline;

		i {
			font-size: 14px;
			margin-left: $block-spacing / 2;
		}
	}

	/* Center */

	&__options {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		gap: $block-spacing * 2;
		margin-top: $block-spacing * 2;
		width: 100%;

		&--hidden {
			display: none;
		}
	}

	/* Result */
	&__result {
		display: flex;
		flex-flow: row wrap;
		border: 1px solid $border-color;
		border-radius: 16px;
		padding: $block-spacing * 5;
		flex: 1 1 auto;
		margin: $block-spacing * 4 0;
	}

	&__result-left {
		flex: 1 1 auto;
		width: 45%;
		padding-right: $block-spacing * 5;
	}

	&__result-right {
		width: 55%;
		display: flex;
		flex-flow: row wrap;
		border-left: 1px solid $border-color;
		padding-left: $block-spacing * 5;
		align-items: center;
	}

	&__result-bottom-right {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		gap: $block-spacing;
	}

	&__result-price {
		display: flex;
		flex-flow: row wrap;
		font-size: 12px;
		line-height: 1.5em;
		flex: 1;
		white-space: nowrap;

		&__highlight {
			color: $font-color;
			margin: 0;
			font-weight: 700;
		}

		&__advice {
			text-decoration: line-through;
			color: $font-color-3;
			margin-right: $block-spacing;
		}
	}

	&__form {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		gap: $block-spacing * 2;
		width: 100%;

		.title {
			width: 100%;
			margin-bottom: $block-spacing;
		}

		.mdc-text-field {
			width: 415px;
			max-width: 100%;
		}
	}

	&--fixed {
		.choices__container {
			z-index: 10;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 1140px;
			max-width: 100%;
			max-height: 100%;
		}

		.choices__overlay {
			content: ' ';
			display: block;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $black;
			opacity: 0.5;
			z-index: 9;

			&--loading {
				z-index: 11;
			}
		}
	}

	&--hidden {
		display: none;
	}

	.sitemap__link {
		&--hidden {
			display: none;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		.choices__result-left,
		.choices__result-right {
			width: 100%;
			padding: 0;
			border: 0;

			.title {
				margin-top: $block-spacing * 4;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		&__top {
			padding: $block-spacing * 2;
		}

		&__top-left {
			order: 2;
			margin-top: $block-spacing;
			width: 100%;
		}

		&__top-right {
			order: 1;
			width: 100%;
		}

		&__center {
			padding: 0 $block-spacing * 2 $block-spacing * 2 $block-spacing * 2;
		}

		&__bottom-right {
			.button {
				flex: 1 1 auto;
			}
		}
	}

	@include responsive(('mobile')) {
		&__result {
			padding: $block-spacing * 2;
		}

		&__result-bottom-right {
			.button {
				flex: 1 1 auto;
			}
		}
	}
}
