.steps {
	/* Containers */
	&__container {
		background: $white;
		display: flex;
		flex-flow: row wrap;
	}

	&__left {
		background-color: $background-color-3;
		width: 330px;
		padding: $block-spacing * 4;
		height: 100%;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		overflow-y: auto;

		.media,
		img {
			mix-blend-mode: darken;
		}
	}

	&__right {
		flex: 1;
		display: flex;
		flex-flow: column wrap;
		height: 100%;
		justify-content: space-between;
	}

	&__top {
		background-color: $white;
		padding: $block-spacing * 4;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-start;

		&--hidden {
			display: none;
		}
	}

	&__top-left {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column wrap;
		justify-content: flex-start;
	}

	&__top-right {
		flex: 1 1 auto;
	}

	&__center {
		flex: 1;
		height: 100%;
		overflow-y: auto;
		padding: 0 $block-spacing * 4 $block-spacing * 4 $block-spacing * 4;
		padding-bottom: calc(50px + #{$block-spacing * 4});
		position: relative;

		&--hidden {
			display: none;
		}
	}

	&__bottom {
		display: flex;
		padding-left: 150px;
		padding-right: $block-spacing * 4;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		background-color: $background-color-3;
		height: 100px;
		background-image: url('/_zorgtotaal/img/footer.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center left $block-spacing * 4;
		overflow: hidden;
	}

	/* Top */
	&__progress {
		color: $font-color;
		font-size: 12px;
		display: flex;
		flex-flow: row wrap;
		gap: $block-spacing;
		align-items: center;
		justify-content: flex-start;
		margin-top: $block-spacing;
	}

	&__progress-bar {
		height: 8px;
		background-color: $background-color-5;
		border-radius: 50px;
		flex: 1 1 auto;

		span {
			background-color: $brand-color;
			height: 100%;
			border-radius: 50px;
			display: block;
			min-width: 1%;
			max-width: 100%;
			transition: width 0.3s ease-in-out 0s;
		}
	}

	&__close {
		display: flex;
		flex-flow: column wrap;
		align-items: flex-end;
		cursor: pointer;
	}

	&__close-heading {
		color: $brand-color;
		font-size: 16px;
		margin-top: 16px;
		cursor: pointer;
		display: flex;
		align-items: center;

		&--text {
			text-decoration: underline;
		}

		i {
			font-size: 24px;
			margin-left: $block-spacing;
		}
	}

	&__open-options {
		color: $brand-color;
		font-size: 16px;
		text-decoration: underline;
		cursor: pointer;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;
	}

	&__close-content {
		color: $font-color;
		font-style: italic;
		font-size: 12px;
		margin-top: $block-spacing * 1.5;
	}

	/* Left */

	&__summary {
		margin-top: $block-spacing * 3;
		letter-spacing: -0.28px;
	}

	&__summary-top {
		color: $font-color-2;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: $block-spacing;

		span {
			&:last-child {
				color: $font-color;
			}
		}
	}

	&__summary-center {
		font-size: 14px;
		line-height: 24px;
		color: $font-color-2;
		cursor: pointer;
		position: relative;

		&::before {
			position: absolute;
			top: 8px;
			left: 8px;
			width: 1px;
			height: calc(100% - 18px);
			background-color: $black;
			content: ' ';
			display: block;
		}
	}

	&__summary-link {
		cursor: pointer;
	}

	&__selected {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
		padding-left: $block-spacing * 3;

		i {
			visibility: hidden;
			margin-left: $block-spacing / 2;
		}

		li {
			&:last-child {
				color: $font-color;
			}
		}

		&::before {
			position: absolute;
			top: 4px;
			left: 0;
			display: block;
			height: 17px;
			width: 17px;
			border-radius: 17px;
			content: ' ';
			background-color: $border-color-3;
		}

		&:hover {
			color: $brand-color;

			i {
				visibility: visible;
			}

			&::before {
				background-color: $brand-color;
			}
		}

		&--current {
			&::before {
				background-image: url('/_zorgtotaal/img/icons/check-solid-white.svg');
				background-size: 10px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		&--checked {
			&::before {
				background-image: url('/_zorgtotaal/img/icons/check-solid-white.svg');
				background-size: 10px;
				background-position: center;
				background-repeat: no-repeat;
				background-color: $brand-color;
			}
		}
	}

	&__summary-bottom {
		margin-top: $block-spacing * 2;
		padding-top: $block-spacing * 2;
		border-top: 1px solid $border-color-2;
		color: $font-color-2;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__summary-total {
		text-align: right;
		font-size: 14px;
		color: $brand-color;
		margin-top: $block-spacing * 2;

		&--hidden {
			display: none;
		}
	}

	.media {
		margin-top: $block-spacing;
		width: calc(100% - 80px);
		margin-left: 40px;
		margin-bottom: $block-spacing * 2;
	}

	/* Right */

	&__options {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		gap: $block-spacing * 2;
		margin-top: $block-spacing * 4;

		&--mobile {
			display: none;
		}
	}

	&__options-size {
		display: contents;

		&--hidden {
			display: none;
		}
	}

	&__options-color {
		display: contents;

		&--hidden {
			display: none;
		}
	}

	&__total {
		padding-bottom: $block-spacing * 4;
		border-bottom: 1px solid $border-color-2;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		gap: $block-spacing;
	}

	&__total-price {
		line-height: 1.5em;
	}

	&__total-left {
		flex: 1 1 auto;
	}

	&__total-right {
		display: flex;
		flex: 1 1 auto;
		flex-flow: row wrap;
		gap: $block-spacing;
		align-items: center;
		justify-content: flex-end;
	}

	&__total-price-msrp {
		&--hidden {
			display: none;
		}
	}

	&__buttons {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-end;
		gap: $block-spacing * 2;
		padding-right: $block-spacing * 2;
		padding-top: $block-spacing * 2;
		position: fixed;
		bottom: 116px;
		right: 16px;
	}

	&--fixed {
		.steps__container {
			z-index: 10;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: calc(#{$grid-width} - #{$block-spacing * 2});
			height: 920px;
			max-width: 100%;
			max-height: 100%;
		}

		.steps__overlay {
			content: ' ';
			display: block;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $black;
			opacity: 0.5;
			z-index: 9;
		}
	}

	&__open-summary,
	&__phone {
		display: none;
	}

	&__text {
		display: none;
	}

	&--hidden {
		display: none;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__open-summary-down,
		&__open-summary-up {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			gap: $block-spacing;
		}

		&__open-summary,
		&__phone {
			display: flex;
			gap: $block-spacing;
			flex-flow: row wrap;
			color: $brand-color;
			font-size: 12px;
			font-weight: bold;
			padding: $block-spacing;
			cursor: pointer;
		}

		&__open-summary {
			padding-left: 0;

			span {
				text-decoration: underline;
			}

			.steps__open-summary-up {
				display: none;
			}

			&--active {
				.steps__open-summary-up {
					display: flex;
				}

				.steps__open-summary-down {
					display: none;
				}
			}
		}

		&__phone {
			border-radius: 30px;
			background-color: $white;
			border: 1px solid $brand-color;
		}

		&__left {
			display: none;

			&--active {
				display: flex;
				position: fixed;
				top: 62px;
				left: 0;
				right: 0;
				bottom: 83px;
				width: auto;
				height: auto;
				z-index: 1;
			}
		}

		&__top {
			padding: 0;
		}

		&__options {
			margin-top: $block-spacing * 2;

			&--desktop {
				display: none;
			}

			&--mobile {
				display: flex;
			}
		}

		&__close {
			flex: 1;
		}

		&__close-heading {
			margin-top: 0;
		}

		&__close-heading--text {
			display: none;
		}

		&__top-left {
			order: 2;
			margin-top: 0;
			width: 100%;
			padding: $block-spacing * 2;
		}

		&__top-right {
			order: 1;
			width: 100%;
			background-color: $background-color;
			padding: $block-spacing * 2;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
		}

		&__center {
			padding: $block-spacing * 2;
			padding-bottom: calc(#{$block-spacing * 4} + 83px);
		}

		&__buttons {
			padding: $block-spacing * 2;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: $background-color;
			gap: $block-spacing;
			flex-flow: row nowrap;

			.button {
				flex: 1 1 auto;
			}
		}

		&__bottom {
			display: none;
		}

		&__total-right {
			.button {
				flex: 1 1 auto;
			}
		}

		&--fixed {
			.steps__container {
				height: 100vh;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		&__text {
			display: block;
			margin-bottom: $block-spacing * 4;
		}
	}

	@include responsive(('mobile')) {
		&__buttons {
			.button {
				flex: 1 1 auto;
			}
		}
	}
}

.invoice {
	&__container {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		padding: 32px;
		overflow-y: auto;
	}

	&--hidden {
		display: none;
	}

	&--fixed {
		.invoice__container {
			z-index: 10;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: calc(#{$grid-width} - #{$block-spacing * 2});
			max-width: 100%;
			max-height: 100%;

			.invoice_shipping {
				&--hidden {
					display: none;
				}
			}
		}

		.invoice__overlay {
			content: ' ';
			display: block;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $black;
			opacity: 0.5;
			z-index: 9;
		}
	}
}
