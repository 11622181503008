.compare {
	&--pdp {
		display: flex;
		flex-flow: row wrap;
		flex-shrink: 0;
		flex-grow: 1;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		gap: $block-spacing * 4;
		padding: $block-spacing;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			justify-content: flex-start;
			gap: $block-spacing * 1.5;
			flex-shrink: 1;
		}
	}
}
