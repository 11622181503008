@mixin background() {
	@at-root .background {
		background-color: $background-color;
		width: 100%;

		&--white {
			background-color: $white;
		}

		&--2 {
			background-color: $background-color-2;
		}

		&--3 {
			background-color: $background-color-3;
		}

		&--4 {
			background-color: $background-color-4;
		}

		&--5 {
			background-color: $background-color-5;
		}

		&--6 {
			background-color: $background-color-6;
		}

		&--border-radius {
			border-radius: 16px;
			overflow: hidden;
		}

		&--border {
			border: 1px solid $border-color;
		}

		&--shadow {
			box-shadow: 0 0 32px 0 rgb(0 0 0 / 10%);
		}

		&--spacing-3x {
			padding: $block-spacing * 3;
		}

		&--spacing-4x {
			padding: $block-spacing * 4;
		}

		&--margin-bottom-2x {
			margin-bottom: $block-spacing * 2;
		}

		&--margin-bottom-3x {
			margin-bottom: $block-spacing * 3;
		}

		&--showroom-banner {
			position: absolute;
			bottom: -$vertical-spacing;
			left: $block-spacing;
			width: 680px;
			z-index: 1;

			@include responsive(('tablet-landscape')) {
				bottom: -$vertical-spacing-mobile;
			}

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				bottom: auto;
				left: auto;
				position: relative;
				width: calc(100% - #{$block-spacing * 2});
				margin: $block-spacing;
			}
		}

		&--h-full {
			height: 100%;
		}

		&--author {
			background-position: bottom right;
			background-repeat: no-repeat;
			background-size: auto 250px;
			padding-right: 250px;
			min-height: 250px;

			@include responsive(('mobile', 'mobile-landscape')) {
				padding-bottom: 250px;
				padding-right: $block-spacing * 4;
			}
		}

		&--brochure {
			width: 448px;
			max-width: 100%;
			position: absolute;
			bottom: 0;
			right: -30px;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				width: 100%;
				position: relative;
				bottom: auto;
				right: auto;
				margin-top: $block-spacing * 2;
				margin-bottom: $vertical-spacing-mobile;
			}
		}

		&--showroom-category {
			background-position: bottom right;
			background-repeat: no-repeat;
			background-size: 33%;

			@include responsive(('mobile', 'mobile-landscape')) {
				background-size: auto 250px;
				padding-bottom: 250px;
			}
		}

		&--product-cs {
			padding: $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding: $block-spacing * 4 8.3333%;
			}
		}

		&--faq {
			padding: $block-spacing * 4;
		}

		&--product-usp {
			margin-top: $block-spacing * 2;
			padding: $block-spacing * 4 $block-spacing * 4 $block-spacing * 2 $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding-left: 8.3333%;
				padding-right: 8.3333%;
			}
		}

		&--product-description {
			margin-top: $block-spacing * 2;
			padding: $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding-left: 8.3333%;
				padding-right: 8.3333%;
			}
		}

		&--brand {
			min-height: 500px;
			display: flex;
			align-items: center;

			@include responsive(('desktop')) {
				min-height: 447px;
			}

			@include responsive(('tablet-landscape')) {
				min-height: 372px;
			}

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				min-height: unset;
			}
		}

		&--look {
			background: linear-gradient(to right, transparent 0%, transparent 30%, $white 30%, $white 100%);

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				background: $white;
			}
		}

		&--vertical-spacing {
			margin-top: $vertical-spacing;
			margin-bottom: $vertical-spacing;
		}

		&--vertical-spacing-top {
			margin-top: $vertical-spacing;
		}

		&--vertical-spacing-bottom {
			margin-bottom: $vertical-spacing;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			&--vertical-spacing {
				margin-top: $vertical-spacing-mobile;
				margin-bottom: $vertical-spacing-mobile;
			}

			&--vertical-spacing-top {
				margin-top: $vertical-spacing-mobile;
			}

			&--vertical-spacing-bottom {
				margin-bottom: $vertical-spacing-mobile;
			}
		}

		@content;
	}
}
