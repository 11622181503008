.notify {
	display: flex;
	flex-flow: row wrap;

	.mdc-text-field {
		margin-bottom: $block-spacing * 2;
	}

	.button {
		flex: 1 1 auto;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
	}

	&--hidden {
		display: none;
	}
}
