@include sorting {
	justify-content: flex-end;
	gap: $block-spacing * 2;

	&__content {
		font-size: 16px;
		color: $font-color-2;
		padding: 0;
	}

	&__select {
		border: 1px solid $border-color;
		border-radius: 50px;
		padding: $block-spacing * 2 $block-spacing * 5 $block-spacing * 2 $block-spacing * 3;
		font-size: 12px;
		font-weight: 400;
		color: $font-color;
		background-color: $white;
		background-image: url('/_zorgtotaal/img/icons/angle-down.png');
		background-position: center right #{$block-spacing * 2};
		background-repeat: no-repeat;
		background-size: 12px;
	}

	@include responsive(('tablet-landscape')) {
		margin-top: $block-spacing * 2;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		margin-top: 0;
		justify-content: flex-start;

		&__content {
			font-size: 14px;
			width: 100%;
		}

		&__select {
			width: 100%;
		}
	}
}
