.sticky-bar {
	position: fixed;
	bottom: 48px;
	right: 30px;
	user-select: none;
	z-index: 9;

	&__container {
		display: flex;
		flex-direction: column;
		gap: $block-spacing * 2;
		position: relative;
		align-items: flex-end;
		justify-content: flex-end;

		.button {
			font-weight: normal;
			flex: 1;
			width: 100%;

			i {
				display: none;
			}
		}
	}

	&__item {
		background-color: $black;
		width: 49px;
		height: 49px;
		border-radius: 49px;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		cursor: pointer;

		&:last-child {
			margin-bottom: 0;
		}

		&--flipped {
			transform: rotate(180deg);
		}
	}

	&__icon {
		font-size: 18px;

		&--arrow {
			font-size: 16px;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
		right: $block-spacing;

		&__container {
			gap: $block-spacing;

			.button {
				min-width: unset;
				width: 49px;
				height: 49px;
				line-height: 49px;
				flex: unset;
				font-size: 16px;
				display: flex;
				flex-flow: column wrap;
				align-items: center;
				justify-content: center;

				i {
					display: block;
				}
			}
		}

		&__text {
			width: 0;
			overflow: hidden;
		}

		.button:hover {
			width: auto;

			.sticky-bar__text {
				width: auto;
				overflow: visible;
			}
		}
	}
}
