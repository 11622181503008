.table {
	font-size: 14px;
	color: $font-color-2;
	margin-bottom: $block-spacing * 4;
	min-width: 100%;

	&__tr {
		background-color: $white;

		&:nth-child(odd) {
			background-color: $background-color-6;
		}

		&:nth-child(-n + 2) {
			.tooltip__message {
				top: $block-spacing * 3;
				bottom: inherit;
			}
		}
	}

	&__td {
		padding: $block-spacing;
	}

	a {
		color: $brand-color;
		text-decoration: underline;
	}

	i {
		color: $brand-color;
		margin-right: $block-spacing;
	}

	&__link {
		display: block;
		text-align: center;
		text-decoration: underline;
		color: $brand-color;
		margin-bottom: $block-spacing * 4;
		font-size: 14px;
	}

	&--2-cells {
		.table__td {
			width: 50%;
		}
	}

	&--4-cells {
		.table__td {
			width: 25%;
		}
	}

	&--compare {
		table-layout: fixed;
		width: 100%;

		.table__td {
			height: 30px;
			white-space: nowrap;
			width: inherit;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
