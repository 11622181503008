@include product-images {
	@extend %flex-row;

	position: relative;

	&__image {
		width: calc(50% - #{$block-spacing * 2});
		display: block;
		margin: $block-spacing;
		order: 3;

		&:first-child {
			order: 1;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			width: 100%;
		}
	}

	&__video {
		position: relative;
		overflow: hidden;
		order: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		width: calc(50% - #{$block-spacing * 2});
		margin: $block-spacing;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 143.3%;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 100%;
			width: 253%;
			z-index: 5;
		}

		&--loader {
			font-size: 40px;
			color: $black;
			animation-duration: 2s;
			animation-name: loader-loading;
			animation-iteration-count: infinite;

			@keyframes loader-loading {
				from {
					transform: rotate(0deg);
				}

				to {
					transform: rotate(360deg);
				}
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			width: 100%;
		}
	}

	&-extended {
		position: relative;
		width: 100%;

		&__thumbnail {
			width: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			border-radius: 0 16px 16px 0;

			&::after {
				display: block;
				content: ' ';
				padding-bottom: 100%;
			}
		}

		&__controls {
			position: absolute;
			display: flex;
			flex-flow: row wrap;
			gap: $block-spacing * 2;
			right: 32px;
			bottom: 32px;

			@include responsive(('mobile')) {
				left: $block-spacing * 2;
				right: $block-spacing * 2;
				bottom: $block-spacing * 2;
				align-items: center;
				justify-content: center;
			}
		}

		&--hidden {
			display: none;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			margin-bottom: $block-spacing * 2;
		}
	}

	&__bullets {
		position: absolute;
		bottom: $block-spacing * 4;
		left: 0;
		z-index: 1;
		padding: 0 calc(8.3333% - 4px);
	}

	&__bullet {
		padding: 0;
		border: 1px solid $border-color;
		margin: 4px;
		cursor: pointer;
		width: calc(20% - 8px);
	}

	&__container {
		@extend %flex-row;

		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			display: none;
		}
	}

	&__carousel {
		display: none;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			display: block;
		}
	}
}
