@mixin active-filters {
	@at-root .active-filters {
		@extend %flex-row;

		padding: $block-spacing * 2;
		background-color: $white;
		border: 1px solid $border-color;
		border-radius: 16px;
		align-items: center;
		gap: $block-spacing * 1.5;

		&__label {
			display: block;
			width: auto;
			margin: 0;
		}

		&__input {
			display: none;
		}

		&__replacement {
			@extend %paragraph;

			display: none;
			position: relative;
			cursor: pointer;
			padding: $block-spacing;
			color: $font-color;
			font-weight: bold;
			font-size: 14px;
			padding-left: 0;
			padding-right: $block-spacing * 2;

			i {
				@extend %position-right;
			}

			&:hover {
				background-color: transparent;
			}
		}

		&__input:checked + .active-filters__replacement {
			display: block;
		}

		&__count {
			@extend %flex-row;

			align-items: center;
			justify-content: center;
			color: $white;
			height: 30px;
			width: 30px;
			border-radius: 30px;
			font-size: 12px;
			background-color: $brand-color;
			font-weight: bold;
			margin-right: 0;
		}

		&__content {
			font-size: 14px;
			color: $font-color;
			margin-right: 0;
		}

		@content;
	}
}
