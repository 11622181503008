.category {
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
	border: 1px solid $border-color;
	border-radius: 16px;
	background-color: $white;
	position: relative;
	margin-bottom: 24px;

	&__image {
		width: 65%;
		display: block;
		margin: $block-spacing * 3 0 0;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 100%;
		}
	}

	&__name {
		color: $font-color;
		background-color: $white;
		font-family: $primary-font-family;
		font-size: 12px;
		border: 1px solid $border-color;
		border-radius: 50px;
		padding: 15px $block-spacing * 3;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: $block-spacing;
		transform: translateY(50%);

		i {
			font-size: 16px;
		}
	}

	&__info {
		display: none;
	}

	&--image {
		margin-bottom: 0;

		.category__image {
			margin-bottom: $block-spacing * 3;
		}
	}

	&--sub {
		margin: $block-spacing;
		width: calc(33.33% - #{$block-spacing * 2});
		flex-flow: row wrap;
		align-items: flex-start;

		.category__name {
			font-size: 16px;
			font-weight: 700;
			border: 0;
			border-radius: unset;
			padding: $block-spacing * 2 $block-spacing * 4 $block-spacing;
			justify-content: flex-start;
			transform: none;
			width: 100%;

			i {
				display: none;
			}
		}

		.category__info {
			flex-grow: 1;
			justify-content: space-between;
			display: flex;
			flex-flow: column wrap;
			padding: 0 $block-spacing * 4 60px;
			height: 100%;
		}

		.category__link {
			color: $brand-color;
			font-size: 14px;
			font-weight: 700;
			text-decoration: underline;
			position: absolute;
			bottom: $block-spacing * 4;
			left: $block-spacing * 4;
			right: $block-spacing * 4;

			i {
				margin-left: $block-spacing / 2;
			}
		}

		@include responsive(('desktop')) {
			width: calc(50% - #{$block-spacing * 2});
		}

		@include responsive(('mobile-landscape', 'tablet')) {
			width: calc(50% - #{$block-spacing * 2});
		}

		@include responsive(('mobile')) {
			width: calc(100% - #{$block-spacing * 2});
		}
	}
}
