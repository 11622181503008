.number {
	display: flex;
	flex-flow: row nowrap;
	min-height: 49px;
	border: 1px solid $border-color;
	border-radius: 50px;
	cursor: pointer;

	&__button,
	&__input {
		width: 49px;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		color: $font-color;
	}

	&__input {
		@extend %input;

		font-size: 14px;
		color: $font-color;
		border: 0;
		text-align: center;
		line-height: 49px;
		white-space: nowrap;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			appearance: none;
			margin: 0;
		}
	}
}

input[type='number'] {
	appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
	appearance: number-input;
}
