.service {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	width: 100%;

	&__item {
		width: calc(25% - #{$block-spacing * 2});
		margin: $block-spacing;
		background-color: $background-color-3;
		border-radius: 8px;
		padding: $block-spacing * 4;
		gap: $block-spacing * 2;
		flex: 0 1 auto;
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
	}

	&__item-top {
		display: flex;
		flex-flow: row nowrap;
		gap: $block-spacing * 2;
		align-items: center;
	}

	.text {
		flex: 1;
	}

	i {
		font-size: 64px;
		color: $brand-color;
	}

	.button {
		width: 100%;
		height: 49px;
	}

	@include responsive(('tablet', 'tablet-landscape', 'desktop')) {
		&__item {
			padding: $block-spacing * 3;
		}

		&__item-top {
			flex-flow: row wrap;
			align-items: center;
		}

		.text {
			width: 100%;
			flex: unset;
		}
	}

	@include responsive(('mobile-landscape', 'tablet')) {
		&__item {
			width: calc(50% - #{$block-spacing * 2});
			padding: $block-spacing * 3;
		}

		&__item-top {
			flex-flow: row wrap;
			align-items: center;
		}

		.text {
			width: 100%;
			flex: unset;
		}
	}

	@include responsive(('mobile')) {
		&__item {
			width: calc(100% - #{$block-spacing * 2});
		}
	}
}
