.size-select {
	width: 100%;
	position: relative;

	&__dropdown {
		appearance: none;
		width: 100%;
		height: 50px;
		border-radius: 50px;
		border: 1px solid $border-color;
		color: $font-color;
		font-family: $primary-font-family;
		font-size: 14px;
		padding: 0 $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
		background-image: url('#{$image-path}/icons/angle-down.svg');
		background-repeat: no-repeat;
		background-position: center right $block-spacing * 2;
		background-size: 12px;
		padding-right: 144px;
		font-weight: 400;
		margin-top: $block-spacing;
	}

	&__stock {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: $block-spacing;
		height: 50px;
		user-select: none;
		pointer-events: none;
		right: 44px;
		color: $font-color;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
	}

	&--hidden {
		display: none;
	}

	&--one-option {
		display: none;
	}

	&__input {
		display: none;
	}

	&__label {
		user-select: none;
		z-index: 1;
		position: relative;
	}

	&__container {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		gap: calc(14px + #{$block-spacing});
	}

	&__replacement {
		height: 64px;
		width: 64px;
		border-radius: 34px;
		border: 1px solid $border-color;
		box-shadow: none;
		cursor: pointer;
		display: inline-block;
		position: relative;

		&::before {
			display: block;
			content: ' ';
			height: 78px;
			width: 78px;
			border-radius: 78px;
			background-color: $white;
			border: 3px solid $border-color;
			z-index: -1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&--unavailable {
			pointer-events: none;

			&::before {
				opacity: 0;
			}

			&::after {
				display: block;
				content: ' ';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 78px;
				height: 2px;
				transform: translate(-50%, -50%) rotate(-45deg);
				transform-origin: center;
				background-color: $highlight-color-2;
				border: none;
				border-radius: 0;
			}
		}

		&:hover {
			border: 1px solid $border-color;
			box-shadow: none;

			&::before {
				border-color: $highlight-color;
			}
		}
	}

	&__input:checked + .size-select__replacement {
		border: 3px solid $border-color;
		box-shadow: none;

		&::before {
			border: 3px solid $highlight-color;
		}
	}
}
