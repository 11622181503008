.options {
	background-color: $background-color-3;
	padding: $block-spacing * 2;
	border-radius: 8px;
	display: none;
	flex-flow: column wrap;
	gap: $block-spacing;
	margin-bottom: $block-spacing * 2;

	&__label {
		cursor: pointer;
		user-select: none;
		position: relative;
	}

	&__replacement {
		border: 2px solid $border-color;
		border-radius: 8px;
		padding: $block-spacing * 2;
		height: 100%;
		position: relative;
	}

	&__name,
	&__price {
		font-size: 14px;
		color: $font-color-2;
		margin-bottom: $block-spacing;
		width: 100%;

		s {
			text-decoration: line-through;
			font-weight: 400;
		}
	}

	&__image {
		width: 68px;
		height: 68px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	&__top {
		width: 100%;
		display: flex;
		flex-direction: row wrap;
		align-items: flex-start;
		gap: $block-spacing * 2;
	}

	&__bottom {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;
		justify-content: space-between;
		padding-top: $block-spacing * 2;
	}

	&__check {
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__unchecked,
	&__checked {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: $block-spacing;

		i {
			font-size: 24px;
			color: $border-color;
		}
	}

	&__checked {
		display: none;
		color: $brand-color;

		i {
			color: $brand-color;
		}
	}

	&--active {
		display: flex;
	}

	&__input {
		display: none;

		&:checked + .options__replacement {
			border-color: $brand-color;

			.options__unchecked {
				display: none;
			}

			.options__checked {
				display: flex;
			}
		}
	}
}
