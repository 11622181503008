@include sidebar {
	border: 1px solid $border-color;
	border-radius: 16px;
	background-color: $white;
	padding: $block-spacing * 4;
	color: $font-color;
	margin-bottom: $block-spacing * 2;

	&__heading {
		font-size: 20px;
		font-family: $secondary-font-family;
		font-weight: 700;
		letter-spacing: -0.4px;
		line-height: 1.5em;

		&--sub {
			font-family: $primary-font-family;
			font-size: 16px;
			letter-spacing: normal;
			color: $font-color-2;
			padding-bottom: 20px;
			position: relative;
			padding-top: $block-spacing * 2;

			&::after {
				position: absolute;
				left: 0;
				bottom: 8px;
				width: 16px;
				height: 4px;
				border-radius: 4px;
				background-color: $brand-color;
				content: ' ';
				display: block;
			}
		}
	}

	&__link {
		font-size: 16px;
		color: $brand-color;
		font-weight: 700;
		margin-top: $block-spacing;
		margin-bottom: $block-spacing * 2;

		i {
			margin-right: $block-spacing / 2;
		}
	}

	&__content {
		font-size: 16px;
		line-height: 1.5em;
		color: $font-color-2;

		a {
			display: block;
			cursor: pointer;
			margin-top: $block-spacing;
		}

		ul {
			font-size: 14px;
			list-style: inside;

			li {
				padding-left: 8px;
			}

			a {
				display: inline;
				margin: 0;
			}
		}

		a:hover {
			text-decoration: underline;
		}
	}

	&--compare {
		justify-content: center;
		display: flex;
		gap: $block-spacing * 2;
		flex-flow: row wrap;
		width: calc(100% - #{$block-spacing * 2});
		margin: 0 $block-spacing;

		.title {
			padding: $block-spacing * 2 0;
		}

		.text {
			padding-bottom: $block-spacing * 4;
		}
	}

	&--news {
		background: unset;
		border: unset;
		padding: unset;
		margin: unset;

		.sidebar--hidden {
			display: block;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&--hidden {
			display: none;
		}
	}
}
