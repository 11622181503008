@include checkbox {
	display: flex;
	font-size: 14px;
	color: $font-color;
	align-items: center;
	gap: $block-spacing;
	font-family: $primary-font-family;

	&__replacement {
		border-radius: 4px;
		border: 2px solid $border-color;
		margin: 0;
		height: 25px;
		width: 25px;
		flex-shrink: 0;

		&--button {
			min-width: auto;
			width: auto;
			height: auto;
			padding: $block-spacing * 2;
			font-size: 12px;
			text-transform: uppercase;
			color: $black;
			background-color: $white;
			border: 1px solid $black;
			font-weight: bold;
			white-space: nowrap;
		}
	}

	&__input:checked + .checkbox__replacement {
		border: 2px solid $border-color;
		background-image: url('/_zorgtotaal/img/icons/check-green.png');
		background-size: 14px;

		&--button {
			color: $white;
			background-color: $black;
		}
	}

	&__label {
		flex: 1;
	}

	a {
		text-decoration: underline;
		color: $brand-color;
		font-weight: 700;
	}

	&--filters {
		width: 100%;
		padding: 0;
	}

	&--shoppingcart {
		padding: $block-spacing * 2;
		padding-right: 66px;
	}

	&--account {
		display: block;
	}
}

.shoppingcart-total + .checkbox {
	justify-content: flex-end;
	margin-bottom: $block-spacing * 2;
}
