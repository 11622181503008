@mixin news-item() {
	@at-root .news-item {
		background-color: $white;
		border: 1px solid $border-color;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		border-radius: 16px;
		margin-left: $block-spacing;
		margin-right: $block-spacing;
		margin-top: $block-spacing * 2;
		flex: 1 1 auto;
		overflow: hidden;

		&__content {
			width: calc(100% - 320px);
			display: flex;
			flex-flow: column wrap;
			flex: 1 1 auto;
			padding: $block-spacing * 4;
			gap: $block-spacing * 2;
			justify-content: center;

			@include responsive(('desktop')) {
				width: calc(100% - 220px);
			}

			@include responsive(('mobile', 'mobile-landscape')) {
				width: 100%;
			}
		}

		&__image {
			width: 320px;
			height: 240px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			&--2 {
				display: none;
			}

			@include responsive(('desktop')) {
				width: 220px;
			}

			@include responsive(('mobile', 'mobile-landscape')) {
				display: none;
			}
		}

		&__date {
			font-style: italic;
			font-size: 16px;
			line-height: 27px;
		}

		&__bottom {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			gap: $block-spacing * 2;

			@include responsive(('mobile', 'mobile-landscape')) {
				justify-content: space-between;
			}
		}

		.title {
			font-size: 20px;
		}

		&--small {
			height: 100%;
			display: block;
			align-items: flex-start;
			position: relative;
			margin: 0;

			.news-item__content {
				width: 100%;
				padding-bottom: 176px;
			}

			.news-item__image {
				display: none;
			}

			.news-item__bottom {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				padding: $block-spacing $block-spacing * 4 $block-spacing * 4 $block-spacing * 4;
			}
		}

		&--large {
			min-height: 480px;
			position: relative;
			align-items: stretch;
			margin-top: $block-spacing;

			.title {
				font-size: 32px;
			}

			.text,
			.title {
				color: $white;
			}

			.news-item__date {
				color: $white;
			}

			.news-item__content {
				z-index: 1;
				background-color: rgba($black, 0.75);
				justify-content: flex-end;
				gap: $block-spacing * 3;
			}

			.news-item__bottom {
				justify-content: space-between;
			}

			.news-item__image {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-size: cover;

				&--1 {
					display: none;
				}

				&--2 {
					display: block;
				}
			}
		}

		@content;
	}
}
