.sticky-atb {
	z-index: 1;
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	background-color: $white;

	.media {
		width: 84px;
	}

	&__container {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 16px;
		padding: $block-spacing 0;

		.button {
			flex: 1 1 auto;
		}
	}

	&--hidden {
		display: none;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		.media {
			display: none;
		}
	}
}
