.popup {
	@extend %flex-column;

	flex-wrap: nowrap;
	z-index: 10;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 374px;
	border: 3px solid $background-color;
	background-color: $white;
	padding: $block-spacing * 2;
	max-width: calc(100% - #{$block-spacing * 4});
	max-height: calc(100vh - #{$block-spacing * 4});

	&__scroll {
		overflow-y: auto;
		flex: 1;
	}

	&__title {
		@extend %heading3;

		text-align: center;
		color: $black;
		font-weight: bold;
		font-family: $secondary-font-family;
	}

	&__summary {
		@extend %heading6;

		text-align: center;
		color: $black;
		font-weight: bold;
		margin-bottom: $block-spacing * 2;
		font-family: $secondary-font-family;
	}

	&__content {
		@extend %paragraph;

		color: $black;
		text-align: center;
		margin-bottom: $block-spacing * 2;

		p {
			margin-bottom: $block-spacing * 2;
		}

		h2,
		h3,
		h4 {
			margin-top: $block-spacing * 3;
			margin-bottom: $block-spacing;
		}

		ul {
			list-style: disc outside;
			padding-left: 18px;
			margin-bottom: $block-spacing * 2;
		}

		ol {
			list-style: auto outside;
			padding-left: 18px;
			margin-bottom: $block-spacing * 2;
		}

		em {
			font-style: italic;
		}

		u {
			text-decoration: underline;
		}

		a {
			text-decoration: none;
			color: $highlight-color;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__close {
		position: absolute;
		top: $block-spacing * 2;
		right: $block-spacing * 2;
		color: $black;
		font-size: 40px;
		cursor: pointer;

		&--text {
			color: $brand-color;
			font-size: 14px;
			text-decoration: underline;
		}
	}

	&__container {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;

		.mdc-text-field {
			// width: calc(50% - #{$block-spacing / 2});
			margin-bottom: $block-spacing;

			&:last-child {
				width: 100%;
			}
		}

		&--hidden {
			display: none !important;
		}
	}

	.button {
		margin-top: $block-spacing;
	}

	&--message {
		padding: $block-spacing * 3;
		width: 541px;
		align-items: flex-start;

		.popup__title {
			text-align: left;
			font-size: 20px;
			margin-bottom: $block-spacing * 2;
		}

		.popup__content {
			text-align: left;
			color: $font-color-2;
			margin-bottom: 0;
		}

		.popup__close {
			top: $block-spacing * 3;
			right: $block-spacing * 3;
		}
	}

	&--hidden {
		display: none;
	}
}
