@mixin pagination {
	@at-root .pagination {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		gap: $block-spacing;
		padding: $block-spacing * 3 $block-spacing;
		width: 100%;

		&__page {
			font-size: 14px;
			display: flex;
			transition: all 0.3s ease-in-out 0s;
			align-items: center;
			justify-content: center;
			color: $brand-color;
			background-color: $white;
			height: 48px;
			min-width: 48px;
			border-radius: 48px;
			border: 1px solid $border-color;
			cursor: pointer;

			&:hover {
				border-color: $brand-color;
				color: $font-color;
			}

			&--active {
				border: 2px solid $brand-color;
				color: $font-color;
				font-weight: 700;
			}

			&--disabled {
				pointer-events: none;
				user-select: none;
				opacity: 0.5;
			}

			&--dots {
				color: $font-color;
				background-color: transparent;
				border: 0;
			}

			&--prev,
			&--next {
				padding: 0 $block-spacing * 2;
				font-weight: 700;

				i {
					font-size: 16px;

					&:first-child {
						margin-right: $block-spacing;
					}

					&:last-child {
						margin-left: $block-spacing;
					}
				}
			}
		}

		&--category {
			margin-bottom: $block-spacing;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			&__page {
				&--next,
				&--prev {
					span {
						display: none;
					}

					i {
						&:first-child,
						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}

		@content;
	}
}
