@include shoppingcart-popup {
	color: $black;

	&__total,
	&__producttotal,
	&__promotion {
		font-size: 16px;
	}

	&__bottom {
		display: flex;
		flex-flow: column wrap;
		gap: $block-spacing;
	}

	&__price-table {
		margin-bottom: 0;
	}
}
