@mixin title {
	@at-root .title {
		color: $font-color;
		font-family: $secondary-font-family;
		font-size: 56px;
		line-height: normal;
		letter-spacing: -1.12px;

		&--primary {
			font-family: $primary-font-family;
		}

		&--h1 {
			font-size: 56px;
			line-height: 72px;
			letter-spacing: -1.12px;
		}

		&--h2 {
			font-size: 40px;
			letter-spacing: -0.8px;
		}

		&--h3 {
			font-size: 32px;
			letter-spacing: normal;
		}

		&--h4 {
			font-size: 24px;
			letter-spacing: -0.48px;
		}

		&--h5 {
			font-size: 20px;
			letter-spacing: -0.4px;
		}

		&--h6 {
			font-size: 18px;
			letter-spacing: normal;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--bold {
			font-weight: bold;
		}

		&--align-left {
			text-align: left;
		}

		&--align-center {
			text-align: center;
		}

		&--spacing {
			margin-top: $block-spacing;
			margin-bottom: $block-spacing;
		}

		&--spacing-2x {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-3x {
			margin-top: $block-spacing * 3;
			margin-bottom: $block-spacing * 3;
		}

		&--spacing-top {
			margin-top: $block-spacing;
		}

		&--spacing-top-2x {
			margin-top: $block-spacing * 2;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing;
		}

		&--spacing-bottom-2x {
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-bottom-3x {
			margin-bottom: $block-spacing * 3;
		}

		a {
			color: $brand-color;
			text-decoration: underline;
		}

		&--brand-color,
		&__brand-color {
			color: $brand-color;
		}

		&--border-bottom {
			border-bottom: 1px solid $border-color;
			padding-bottom: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--article {
			margin-bottom: $block-spacing * 3;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			&--h1 {
				font-size: 32px;
				line-height: 1.5em;
				letter-spacing: -0.64px;
			}

			&--h2 {
				font-size: 24px;
				line-height: 1.5em;
				letter-spacing: 0.48px;
			}

			&--h3 {
				font-size: 20px;
				line-height: 1.5em;
			}

			&--h5 {
				font-size: 20px;
				line-height: 1.5em;
				letter-spacing: -0.4px;
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			&--article {
				margin-bottom: $block-spacing;
			}

			&--align-center-tablet {
				text-align: center;
			}
		}

		@content;
	}
}
