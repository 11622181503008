.stock-status {
	padding: $block-spacing 0 $block-spacing * 2 0;

	&__message {
		&--success {
			color: $success-color;
		}

		&--warning {
			color: $warning-color;
		}

		&--error {
			color: $error-color;
		}
	}
}
