@mixin carousel {
	@at-root .carousel {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		opacity: 0;
		transition: opacity 300ms ease-in-out;

		&--visable {
			overflow: visible;
		}

		&--initialized {
			opacity: 1;
		}

		&--pdp {
			.carousel__progress {
				position: unset;
			}
		}

		&__slides {
			height: 100%;
			font-size: 0;
		}

		&__slide {
			vertical-align: top;
			height: 100%;
			display: inline-block;
			white-space: normal;
			padding: $block-spacing;

			&--no-spacing {
				padding: 0;
			}

			&--relative {
				position: relative;
			}
		}

		&__prev {
			@extend %position-left;

			left: -24px;

			@include responsive('mobile') {
				left: -14px;
			}

			&--set {
				left: $block-spacing * 2;
				z-index: 1;
			}

			&--review {
				left: -8px;
			}
		}

		&__next {
			@extend %position-right;

			right: -24px;

			@include responsive('mobile') {
				right: -14px;
			}

			&--set {
				right: $block-spacing * 2;
				z-index: 1;
			}

			&--review {
				right: -8px;
			}
		}

		&__next,
		&__prev {
			outline: none;
			cursor: pointer;
			height: 48px;
			width: 48px;
			color: $white;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $brand-color;
			border-radius: 48px;
			background-color: $brand-color;
			transition: background-color 0.3s ease-in-out 0s;

			&:hover {
				background-color: rgba($brand-color, 0.8);
				border: 1px solid $brand-color;
			}

			&.swiper-button-disabled {
				display: none;
			}

			&--2 {
				border: 1px solid $brand-color;
				background-color: transparent;
				color: $brand-color;

				&:hover {
					color: $white;
				}
			}
		}

		&__progress {
			@extend %flex-row;

			position: absolute;
			justify-content: center;
			align-items: center;
			padding: $block-spacing;
		}

		&__bullet {
			height: 20px;
			width: 20px;
			border-radius: 20px;
			background-color: $background-color;
			margin: $block-spacing;
			cursor: pointer;

			&:hover {
				background-color: $hover-color;
			}

			&--active {
				background-color: $active-color;
			}
		}

		&__bar {
			background-color: $brand-color;
			height: 1px;
			width: 100%;
			margin: $block-spacing * 3 0 $block-spacing * 4;
		}

		&__bar-icon {
			position: absolute;
			top: -45px;
			transform: translateX(-50%);
			left: 0;
			padding: 0 $block-spacing;
			background-color: $white;
			color: $brand-color;
			font-size: 24px;
			z-index: 1;
		}

		@content;
	}
}
