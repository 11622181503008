.choice-menu {
	background-color: $white;
	filter: drop-shadow(0 0 32px rgb(0 0 0 / 10%));
	border-radius: 16px;

	&__top {
		padding: $block-spacing * 3 $block-spacing * 3 $block-spacing * 2 $block-spacing * 3;
		display: flex;
		flex-wrap: wrap;
	}

	&__title {
		color: $font-color;
		font-size: 20px;
		font-weight: 700;
		font-family: $secondary-font-family;
		width: 100%;
		line-height: 1.5em;
	}

	&__select {
		@extend %select;

		border: 1px solid $brand-color;
		border-radius: 50px;
		box-shadow: 0 0 16px 0 rgb(0 0 0 / 25%);
		padding: $block-spacing * 2 $block-spacing * 5 $block-spacing * 2 $block-spacing * 3;
		font-size: 14px;
		font-weight: 700;
		color: $brand-color;
		background-color: $white;
		background-image: url('/_zorgtotaal/img/icons/angle-down-green.png');
		background-position: center right #{$block-spacing * 2};
		background-repeat: no-repeat;
		background-size: 12px;
	}

	&__content {
		color: $font-color-2;
		font-size: 16px;
		display: flex;
		flex-wrap: wrap;
		gap: $block-spacing * 2;
		align-items: center;
		line-height: 1.5em;
	}

	&__bottom {
		padding: $block-spacing * 4 $block-spacing * 3;
		border-top: 1px solid $border-color;
		display: flex;
		flex-wrap: wrap;
		gap: $block-spacing * 2;
		font-size: 12px;
		color: $font-color;

		a {
			text-decoration: underline;

			&:hover {
				color: $brand-color;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__top {
			gap: $block-spacing * 3;
			padding: $block-spacing * 3;
		}

		&__bottom {
			gap: $block-spacing * 3;
			padding: $block-spacing * 3;
		}

		&__content {
			gap: $block-spacing * 3;
		}
	}

	@include responsive(('mobile')) {
		&__select {
			flex: 1 1;
		}
	}
}
