.product-form {
	display: flex;
	flex-flow: row wrap;
	gap: $block-spacing * 2;

	.mdc-text-field,
	.mdc-select,
	.button,
	.text {
		width: calc(33.33% - #{$block-spacing * 4});
		flex: 1 1 auto;
	}

	.button {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
	}

	@include responsive(('mobile-landscape', 'tablet')) {
		.mdc-text-field,
		.mdc-select,
		.button,
		.text {
			width: calc(50% - #{$block-spacing * 4});
		}
	}

	@include responsive(('mobile')) {
		.mdc-text-field,
		.mdc-select,
		.button,
		.text {
			width: 100%;
		}
	}
}
