.form-options {
	display: flex;
	flex-flow: row wrap;
	gap: $block-spacing * 2;
	align-items: stretch;

	&__label {
		width: calc(33.33% - #{$block-spacing * 2});
		flex: 1 1 auto;

		@include responsive(('tablet-landscape')) {
			width: calc(33.33% - #{$block-spacing * 2});
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			width: 100%;
		}
	}

	&__unchecked,
	&__checked {
		font-size: 24px;
	}

	&__unchecked {
		color: $border-color;
	}

	&__checked {
		color: $brand-color;
		display: none;
	}

	&__replacement {
		border: 2px solid $border-color;
		color: $font-color-2;
		border-radius: 8px;
		font-size: 16px;
		line-height: normal;
		letter-spacing: -0.32px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding: $block-spacing * 2;
		gap: $block-spacing * 1.5;
		background-color: $white;
		cursor: pointer;
		user-select: none;
		height: 100%;
	}

	&__input {
		display: none;

		&:checked + .form-options__replacement {
			background-color: $background-color-6;
			border: 2px solid $brand-color;

			.form-options__unchecked {
				display: none;
			}

			.form-options__checked {
				display: block;
			}
		}
	}
}
