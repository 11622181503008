@include terms-and-conditions {
	align-items: center;
	justify-content: flex-end;
	padding: 0;

	&__item {
		font-size: 12px;
		line-height: 1.5em;
		color: $white;
		padding: $block-spacing;
		position: relative;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		justify-content: center;
	}
}
