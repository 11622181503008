.collapsable {
	&__container {
		overflow-y: hidden;
		transition: height 0.3s ease-in-out 0s;
	}

	&__button {
		margin-top: $block-spacing * 2;
		text-decoration: none;
		color: $brand-color !important;
		cursor: pointer;

		&--hidden {
			display: none;
		}
	}
}
