@include button {
	font-size: 14px;
	line-height: 1.5em;
	padding: 14px $block-spacing * 2;
	border: 0;
	font-weight: 700;
	border-radius: 50px;
	font-family: $primary-font-family;

	&__count {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: -5px;
		right: -5px;
		min-width: 20px;
		height: 20px;
		border-radius: 20px;
		color: $white;
		font-size: 12px;
		background-color: $highlight-color-2;
	}

	&__icon {
		font-size: 16px;
		margin-left: $block-spacing;
	}

	&--1 {
		background-color: $brand-color;
		color: $white;

		&:hover {
			background-color: rgba($brand-color, 0.9);
		}
	}

	&--2 {
		background-color: $white;
		color: $brand-color;
		border: 1px solid $brand-color;

		&:hover {
			background-color: $background-color;
		}
	}

	&--3 {
		background-color: $highlight-color-3;
		color: $white;

		&:hover {
			background-color: rgba($highlight-color-3, 0.9);
		}
	}

	&--4 {
		background-color: rgba($background-color-2, 0.5);
		color: $white;

		i {
			margin-right: $block-spacing;
			font-size: 16px;
		}

		&:hover {
			background-color: $background-color-2;
		}
	}

	&--relative {
		position: relative;
	}

	&--position-right {
		right: $block-spacing;
	}

	&--spacing {
		margin-top: $block-spacing;
		margin-bottom: $block-spacing;
	}

	&--spacing-top {
		margin-top: $block-spacing;
	}

	&--spacing-top-2x {
		margin-top: $block-spacing * 2;
	}

	&--spacing-bottom {
		margin-bottom: $block-spacing;
	}

	&--spacing-bottom-2x {
		margin-bottom: $block-spacing * 2;
	}

	&--spacing-right-2x {
		margin-right: $block-spacing * 2;
	}

	&--disabled,
	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&--loading {
		:not(.button__loader) {
			display: none;
		}

		.button__loader {
			margin: 0 auto;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&--mobile-hidden {
			display: none;
		}

		&--full-width-mobile {
			width: 100%;
		}

		&--position-mobile {
			position: unset;
			right: auto;
			top: auto;
			bottom: auto;
			left: auto;
			transform: none;
			margin-left: calc(8.3333% + #{$block-spacing});
			margin-right: calc(8.3333% + #{$block-spacing});
			margin-top: $block-spacing * 3;
			width: calc(100% - #{$block-spacing * 2});
		}

		&--spacing {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-top {
			margin-top: $block-spacing * 2;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing * 2;
		}
	}
}
