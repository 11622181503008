.sitemap {
	color: $font-color;
	padding: $block-spacing;

	&__title {
		font-size: 20px;
		font-family: $secondary-font-family;
		margin-bottom: $block-spacing * 2;
		font-weight: 700;
		display: block;
	}

	&__item {
		font-size: 16px;
		line-height: 1.5em;
		color: $font-color-2;
		display: flex;
		align-items: center;
		margin-bottom: $block-spacing;

		+ .sitemap__title {
			margin-top: $block-spacing * 2;
		}
	}

	&__icon {
		font-size: 32px;
		margin: $block-spacing $block-spacing * 2 $block-spacing 0;
		color: $brand-color;
		min-width: 40px;
		display: inline-block;
	}

	&__link {
		color: $brand-color;
		font-weight: 700;
		text-decoration: underline;
	}

	&--contact {
		width: 90%;
		margin-left: 10%;
	}

	&--desktop {
		width: 30%;
	}

	&--mobile {
		display: none;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@include responsive(('desktop')) {
		&--contact {
			width: 70%;
		}

		&--desktop {
			width: 50%;
		}

		&--3 {
			order: 2;
		}

		&--2 {
			order: 3;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__title {
			margin: $block-spacing * 2 0;
		}

		&--contact {
			width: 100%;
			margin-left: 0;
		}

		&--desktop {
			display: none;
		}

		&--mobile {
			display: block;
			width: 100%;
		}
	}

	@include responsive(('mobile-landscape')) {
		&--contact {
			width: calc(100% - 90px);
		}
	}
}
