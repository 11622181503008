.buttons {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	gap: $block-spacing * 2;

	&--right {
		justify-content: flex-end;
	}

	@include responsive(('mobile')) {
		justify-content: center;

		.button {
			flex: 1 1 auto;
		}
	}
}
