.steps-option {
	width: calc(25% - #{$block-spacing * 2});
	min-height: 265px;
	cursor: pointer;
	user-select: none;
	position: relative;

	* {
		font-style: revert;
	}

	&__replacement {
		border: 2px solid $border-color;
		border-radius: 8px;
		padding: $block-spacing * 2;
		padding-bottom: calc(45px + #{$block-spacing * 2});
		height: 100%;
		position: relative;

		ul {
			list-style: inherit;
			padding: revert;
		}

		p,
		ul {
			margin-bottom: 10px;
		}
	}

	&__top {
		background: $brand-color;
		color: $white;
		font-weight: 700;
		font-size: 14px;
		line-height: 32px;
		letter-spacing: -0.28px;
		text-align: center;
		border-radius: 7px 7px 0 0;
		margin: -16px -16px $block-spacing * 1.5 -16px;
	}

	&__name,
	&__heading {
		font-size: 14px;
		color: $font-color-2;
		font-weight: 700;
		margin-bottom: $block-spacing;
		width: 100%;
		line-height: 21px;

		s {
			text-decoration: line-through;
			font-weight: 400;
		}
	}

	&__color {
		width: 68px;
		height: 68px;
		margin-bottom: $block-spacing * 2;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		mix-blend-mode: darken;
	}

	&__summary {
		font-size: 14px;
		color: $font-color-2;
		padding-bottom: $block-spacing * 2;
		line-height: 21px;

		i {
			margin-right: $block-spacing;
		}
	}

	&__bottom {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;
		justify-content: space-between;
		position: absolute;
		bottom: $block-spacing * 2;
		right: $block-spacing * 2;
		left: $block-spacing * 2;
	}

	&__check {
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-left: auto;
	}

	&__unchecked,
	&__checked {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: $block-spacing;

		i {
			font-size: 24px;
			color: $border-color;
		}
	}

	&__checked {
		display: none;
		color: $brand-color;

		i {
			color: $brand-color;
		}
	}

	&--hidden {
		display: none;
	}

	&__input {
		display: none;

		&:checked + .steps-option__replacement {
			border-color: $brand-color;

			.steps-option__unchecked {
				display: none;
			}

			.steps-option__checked {
				display: flex;
			}
		}
	}

	@include responsive(('desktop')) {
		width: calc(33.33% - #{$block-spacing * 2});
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__summary {
			display: none;
		}
	}

	@include responsive(('tablet')) {
		width: calc(33.33% - #{$block-spacing * 2});
	}

	@include responsive(('mobile-landscape')) {
		width: calc(50% - #{$block-spacing * 2});
	}

	@include responsive(('mobile')) {
		width: calc(100% - #{$block-spacing * 2});
		flex: 1 1 auto;

		&--mobile {
			min-height: unset;

			.steps-option__replacement {
				padding-bottom: $block-spacing * 2;
			}

			.steps-option__summary {
				padding-bottom: 0;
			}

			.steps-option__bottom {
				position: unset;
				bottom: auto;
				right: auto;
				left: auto;

				.steps-option__heading {
					margin-bottom: 0;
					width: auto;
				}
			}
		}
	}
}
