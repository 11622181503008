// path
$font-path: '#{$root}/fonts';
$image-path: '#{$root}/img';

// fonts
// $gazpacho2: gazpacho2;
$primary-font-family: 'Inter', sans-serif;
// $secondary-font-family: $gazpacho2; //, serif;
$secondary-font-family: 'Playfair Display', serif;

// colors
$black: #000;
$white: #fff;
$yellow: #f2c94c;

// brand color
$brand-color: #809836;
$brand-color-2: #3e3f40;

// font colors
$font-color: #222;
$font-color-2: #5a5a5a;
$font-color-3: #a6a6a6;

// background colors
$background-color: #e7f2e9;
$background-color-2: #3e3f40;
$background-color-3: #f9faf5;
$background-color-4: #f3f5eb;
$background-color-5: #ededed;
$background-color-6: rgba($brand-color, 0.1);

$body-background-color: $white;

// border colors
$border-color: #e4e4e4;
$border-color-2: #a6a6a6;
$border-color-3: #5a5a5a;

// placeholder colors
$placeholder-color: $font-color;

// overlay colors
$overlay-color: $black;

// error and messages colors
$warning-color: #f5a623;
$error-color: #c73b1c;
$success-color: #809836;

// highlight colors
$highlight-color: $brand-color;
$highlight-color-2: #d0380d;
$highlight-color-3: #ee5326;

// hover colors
$hover-color: $highlight-color;

// active colors
$active-color: $highlight-color;

// grid
$grid-width: 1440px;
$block-spacing: 8px;
$grid-spacing: $block-spacing;
$grid-column-horizontal-spacing: $block-spacing;
$grid-column-vertical-spacing: $block-spacing;

$vertical-spacing: 100px;
$vertical-spacing-mobile: 32px;

$horizontal-spacing: 4%;
$horizontal-spacing-mobile: $block-spacing * 3;

$responsive-max-resolutions: (
	desktop-hd: 1640,
	desktop: 1440,
	tablet-landscape: 1140,
	tablet: 960,
	mobile-landscape: 768,
	mobile: 568,
);

$grid-responsive-modes: (
	desktop: '(max-width: #{(map-get($responsive-max-resolutions, desktop) - 1)}px)',
	tablet-landscape: '(max-width: #{(map-get($responsive-max-resolutions, tablet-landscape) - 1)}px)',
	tablet: '(max-width: #{(map-get($responsive-max-resolutions, tablet) - 1)}px)',
	mobile-landscape: '(max-width: #{(map-get($responsive-max-resolutions, mobile-landscape) - 1)}px)',
	mobile: '(max-width: #{(map-get($responsive-max-resolutions, mobile) - 1)}px)',
);

$responsive-modes: (
	desktop-hd: '(min-width: #{(map-get($responsive-max-resolutions, desktop) - 1)}px)',
	desktop: '(min-width: #{(map-get($responsive-max-resolutions, tablet-landscape) - 1)}px) and #{map-get($grid-responsive-modes, desktop)}',
	tablet-landscape: '(min-width: #{(map-get($responsive-max-resolutions, tablet) - 1)}px) and #{map-get($grid-responsive-modes, tablet-landscape)}',
	tablet: '(min-width: #{(map-get($responsive-max-resolutions, mobile-landscape) - 1)}px) and #{map-get($grid-responsive-modes, tablet)}',
	mobile-landscape: '(min-width: #{(map-get($responsive-max-resolutions, mobile) - 1)}px) and #{map-get($grid-responsive-modes, mobile-landscape)}',
	mobile: '(min-width: 0px) and #{map-get($grid-responsive-modes, mobile)}',
);
