.faq {
	color: $font-color;
	line-height: 1.5em;

	&__question {
		font-size: 14px;
		padding: $block-spacing * 2;
		cursor: pointer;
		border: 1px solid $border-color;
		border-radius: 150px;
		background-color: $white;
		user-select: none;
	}

	&__answer {
		font-size: 16px;
		color: $font-color-2;
		padding: $block-spacing * 3 $block-spacing * 2;

		table {
			margin: 16px 0;
			width: 100%;
		}

		table tr {
			border-bottom: 1px solid $border-color;
		}

		table td,
		table th {
			padding: 4px 8px;
		}

		table tr:nth-child(1) td,
		table th {
			background-color: $background-color;
			font-weight: 700;
		}

		p {
			margin-bottom: $block-spacing * 2;

			&:last-child {
				margin-bottom: 0;
			}
		}

		h2,
		h3,
		h4 {
			margin-top: $block-spacing * 3;
			margin-bottom: $block-spacing;
		}

		ul {
			list-style: disc outside;
			padding-left: 18px;
			margin-bottom: $block-spacing * 2;

			&:last-child {
				margin-bottom: 0;
			}
		}

		ol {
			list-style: auto outside;
			padding-left: 18px;
			margin-bottom: $block-spacing * 2;

			&:last-child {
				margin-bottom: 0;
			}
		}

		em {
			font-style: italic;
		}

		u {
			text-decoration: underline;
		}
	}

	.accordion {
		margin-bottom: $block-spacing * 2;
		background: linear-gradient(to bottom, transparent 0, transparent 29px, #{$background-color-6} 29px, #{$background-color-6} 100%);
		transition: background-color 0.3s ease-in-out 0s;
	}

	.accordion__icon-open,
	.accordion__icon-close {
		right: $block-spacing * 2;
		font-size: 16px;
		color: $font-color;
	}

	.accordion--hidden {
		background: transparent;

		.faq__question {
			background-color: transparent;
		}
	}
}
