@include shoppingcart-table {
	color: $black;

	&__image-placeholder {
		width: 200px;

		@include responsive(('mobile', 'mobile-landscape')) {
			width: 100px;
		}

		&::after {
			padding-bottom: 100%;
		}

		&--popup {
			width: 100px;

			&::after {
				padding-bottom: 100%;
			}
		}
	}

	&__row {
		white-space: normal;
	}

	&__head {
		font-size: 16px;
	}

	&__cell {
		padding-right: $block-spacing;
		font-size: 14px;

		form {
			width: auto;
			display: flex;
		}

		b {
			font-weight: bold;
		}

		&:last-child {
			padding-right: 0;
		}

		&:last-child,
		&:nth-last-child(2) {
			white-space: nowrap;
		}

		&--bb {
			border-bottom: 1px solid $border-color;
		}

		&--product-name {
			font-size: 16px;

			&:last-child,
			&:nth-last-child(2) {
				white-space: normal;
			}
		}
	}

	&__delete {
		&:hover {
			color: $highlight-color;
		}
	}
}
