@mixin button() {
	@at-root .button {
		@extend %button;

		max-width: 100%;
		min-width: 120px;
		transition: all 0.3s ease-in-out 0s;
		transition-property: background-color, color;
		line-height: 1.5em;
		border: 1px solid $border-color;
		background-color: $background-color;
		padding: $block-spacing;
		color: $font-color;

		&:hover {
			text-decoration: none;
			background-color: rgba($background-color, 0.5);
		}

		&__loader {
			width: 0;
			visibility: hidden;
		}

		&--loading {
			font-size: 0;

			.button__loader {
				font-size: 20px;
				width: auto;
				visibility: visible;
			}
		}

		&--float-right {
			float: right;
		}

		&--float-left {
			float: left;
		}

		&--position-right {
			@extend %position-right;
		}

		&--position-center {
			@extend %position-center;
		}

		&--position-bottom-left {
			@extend %position-bottom-left;
		}

		&--position-bottom-right {
			@extend %position-bottom-right;
		}

		&--full-width {
			width: 100%;
		}

		&--back {
			@extend %paragraph;

			i {
				margin-right: $block-spacing;
			}
		}

		&--print,
		&--pay {
			@extend %paragraph;

			float: right;
			background: $background-color;
			min-width: unset;
			margin-left: $block-spacing;
		}

		&--spacing-top {
			margin-top: $block-spacing;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing;
		}

		@content;
	}
}
