@mixin logo {
	@at-root .logo {
		position: relative;
		display: block;
		height: 50px;
		width: 160px;
		margin: $block-spacing;

		&__image {
			@extend %position-center;

			display: block;
			max-height: 100%;
			max-width: 100%;
		}

		&--header {
			position: relative;
			margin-right: $block-spacing * 8;

			&::after {
				content: ' ';
				display: block;
				background-color: $border-color;
				width: 1px;
				height: 100%;
				position: absolute;
				top: 0;
				right: -$block-spacing * 4;
			}
		}

		@include responsive(('desktop')) {
			&--header {
				margin-right: $block-spacing * 4;

				&::after {
					right: -$block-spacing * 2;
				}
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			&--header {
				margin-right: $block-spacing;

				&::after {
					display: none;
				}
			}
		}

		@include responsive(('mobile')) {
			width: 100%;
			max-width: 160px;
			margin-left: 0;
			margin-right: $block-spacing;
		}

		@content;
	}
}
