.tooltip {
	position: relative;
	cursor: pointer;

	&__icon {
		font-size: 24px;
		color: $brand-color-2;
	}

	&--step {
		position: unset;

		.tooltip__message {
			left: 0;
			right: 0;
			bottom: 32px;

			.steps-option__color {
				width: 160px;
				height: 160px;
			}
		}

		@include responsive(('mobile')) {
			position: absolute;
			top: $block-spacing * 2;
			right: $block-spacing * 2;
		}
	}

	@include responsive(('mobile')) {
		&--step-spacing-top {
			top: calc(32px + #{$block-spacing * 2});
		}
	}

	&--option {
		position: unset;

		.tooltip__message {
			left: $block-spacing * 2;
			right: $block-spacing * 2;
			bottom: 42px;
		}
	}

	&:hover {
		i {
			color: $brand-color;
		}
	}
}

.tooltip-message {
	display: block;
	position: absolute;
	left: 0;
	border: 2px solid $font-color;
	border-radius: 8px;
	background-color: $white;
	color: $font-color-2;
	font-size: 14px;
	line-height: 1.5em;
	font-style: italic;
	padding: $block-spacing * 2;
	width: 100%;
	max-width: 400px;
	white-space: normal;
	z-index: 1;

	&--hidden {
		display: none;
	}

	&--image {
		max-width: 200px;
		height: auto;
		margin-bottom: $block-spacing * 2;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		top: 8px;
	}
}

.tooltip-popup {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 50%);
	z-index: 10;

	p,
	ul,
	ol {
		margin-bottom: $block-spacing * 2;
	}

	&--hidden {
		display: none;
	}

	&--image {
		display: block;
		max-width: 30%;
		margin: 0 auto $block-spacing * 4 auto;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			max-width: 80%;
		}
	}

	&--scroll {
		overflow-y: auto;
		flex: 1;
	}

	&__container {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-self: center;
		min-width: 50%;
		max-width: 80%;
		max-height: 90%;
		background-color: $white;
		position: relative;
		border-radius: $block-spacing * 2;
		overflow: hidden;
		width: 100%;

		@include responsive(('desktop-hd')) {
			min-width: 700px;
			max-width: 960px;
		}
	}

	&__content {
		padding: $block-spacing * 4;
		margin-top: $block-spacing * 4;
		max-height: 100%;
		position: relative;
		width: 100%;
		color: $font-color;
		font-size: 16px;
		line-height: 1.5em;
		font-family: $primary-font-family;

		ul {
			list-style: disc inside;
		}

		&:last-child {
			padding-bottom: $block-spacing * 4;
		}
	}

	&__close {
		position: absolute;
		top: $block-spacing * 4;
		right: $block-spacing * 4;
		color: $brand-color;
		cursor: pointer;
		z-index: 11;

		span {
			margin-right: $block-spacing;
		}
	}
}
