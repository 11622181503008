@include product {
	border: 1px solid $border-color;
	border-radius: 16px;
	background-color: $white;
	padding: $block-spacing * 3;
	padding-bottom: calc(50px + #{$block-spacing * 3});
	position: relative;

	&__image-placeholder {
		&::after {
			padding-bottom: 125%;
		}
	}

	&__name {
		font-size: 18px;
		line-height: 1.5em;
		font-family: $secondary-font-family;
		letter-spacing: -0.36px;
		color: $brand-color;
		font-weight: bold;
		/* stylelint-disable */
		display: -webkit-box;
		/* stylelint-enable */
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__summary {
		font-size: 14px;
		line-height: 1.5em;
		color: $font-color;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: $block-spacing * 2;
	}

	&__specs {
		font-size: 14px;
		line-height: normal;
		color: $font-color-3;
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		gap: $block-spacing;

		li {
			display: inline-block;
			border-right: 1px solid $border-color;
			padding-right: $block-spacing;

			&:first-child {
				font-weight: 700;
				color: $font-color;
				border: 0;
				padding-right: 0;
				display: block;
				width: 100%;
			}

			&:last-child {
				border: 0;
				padding-right: 0;
			}
		}
	}

	&__message {
		font-size: 14px;
		color: $font-color;
		line-height: normal;
		padding: $block-spacing * 2 0;

		&--highlight {
			color: $brand-color;
		}
	}

	&__info {
		border-top: 1px solid $border-color;
		margin-top: $block-spacing * 2;
		padding: $block-spacing * 2 0 0;
	}

	&__bottom {
		position: absolute;
		bottom: $block-spacing * 3;
		left: $block-spacing * 3;
		right: $block-spacing * 3;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		gap: $block-spacing;

		.button {
			flex: 0 1 auto;
		}
	}

	&__price {
		font-size: 12px;
		color: $font-color;
		display: flex;
		flex-flow: row wrap;
		gap: $block-spacing / 2;
		justify-content: flex-start;
		flex: 1;

		&__content {
			width: 100%;
		}

		&__advice {
			color: $font-color-3;
		}

		&__highlight {
			font-weight: 700;
			color: $font-color;
		}

		&--large {
			.product__price__highlight {
				font-size: 24px;
				font-weight: normal;
			}
		}
	}

	&__tags {
		display: flex;
		flex-flow: column wrap;
		position: absolute;
		top: $block-spacing;
		left: -$block-spacing * 3;
		padding: $block-spacing * 1.5;
		background-color: $highlight-color-2;
		color: $white;
		font-size: 14px;
		line-height: normal;
		z-index: 2;

		&::after {
			content: ' ';
			display: block;
			position: absolute;
			top: 0;
			left: 100%;
			width: 0;
			height: 0;
			border-top: 29px solid transparent;
			border-bottom: 29px solid transparent;
			border-left: 14px solid $highlight-color-2;
		}

		&--right {
			left: auto;
			right: 0;
			top: 50px;

			&::after {
				display: none;
			}

			&::before {
				content: ' ';
				display: block;
				position: absolute;
				top: 0;
				right: 100%;
				width: 0;
				height: 0;
				border-top: 29px solid transparent;
				border-bottom: 29px solid transparent;
				border-right: 14px solid $highlight-color-2;
			}
		}
	}

	&__tag {
		position: unset;
		top: auto;
		left: auto;
		background-color: transparent;

		&-image {
			position: absolute;
			right: 0;
			top: 0;
			width: 80px;
			height: auto;
			z-index: 5;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	&--total {
		padding-bottom: 200px;
	}

	&--set {
		padding-bottom: 50px;

		.product__tags,
		.compare,
		.button {
			display: none;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		padding-bottom: calc(100px + #{$block-spacing * 3});

		&--total {
			padding-bottom: 200px;
		}

		&--set {
			padding-bottom: 50px;
		}

		&__bottom {
			gap: $block-spacing;

			.button {
				width: 100%;
			}
		}

		.price {
			width: 100%;
		}
	}
}
