@include guide {
	font-size: 12px;
	color: $font-color;
	font-weight: 400;
	width: 100%;
	display: flex;
	align-items: center;
	gap: $block-spacing;
	padding: $block-spacing * 3 0;
	overflow-x: auto;
	overflow-y: hidden;
	flex-wrap: nowrap;
	white-space: nowrap;

	&__item {
		align-items: center;
		padding: 0;

		&:hover {
			text-decoration: none;
			color: $highlight-color;
		}

		&:last-child {
			font-weight: 700;
		}
	}

	&__icon {
		font-size: 12px;
		margin: 0;
	}

	&--category,
	&--article,
	&--shoppingcart {
		padding: $block-spacing * 3 0 0;
	}

	&--pdp {
		flex: 1 1 auto;
		padding-left: $block-spacing;
		padding-right: $block-spacing;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		padding: $block-spacing 0;

		&--pdp {
			padding: $block-spacing;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&--category,
		&--article {
			padding: $block-spacing 0 0;
		}
	}
}
