.choice {
	width: calc(25% - #{$block-spacing * 2});
	min-height: 265px;
	cursor: pointer;
	user-select: none;
	position: relative;

	&__replacement {
		border: 2px solid $border-color;
		border-radius: 8px;
		padding: $block-spacing * 2;
		padding-bottom: calc(45px + #{$block-spacing * 2});
		height: 100%;
		position: relative;
	}

	&__top {
		background: $brand-color;
		color: $white;
		font-weight: 700;
		font-size: 14px;
		line-height: 32px;
		letter-spacing: -0.28px;
		text-align: center;
		border-radius: 5px 5px 0 0;
		margin: -16px -16px $block-spacing * 1.5 -16px;
	}

	&__name {
		font-size: 16px;
		color: $font-color;
		font-weight: 700;
		margin-bottom: $block-spacing;
		width: 100%;

		s {
			text-decoration: line-through;
			font-weight: 400;
		}
	}

	&__image {
		width: 100%;
		margin-bottom: $block-spacing * 2;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		&::after {
			padding-bottom: 85%;
			content: ' ';
			display: block;
		}
	}

	&__summary {
		font-size: 14px;
		color: $font-color-2;
		line-height: 21px;

		i {
			margin-right: $block-spacing;
		}
	}

	&__bottom {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;
		justify-content: space-between;
		position: absolute;
		bottom: $block-spacing * 2;
		right: $block-spacing * 2;
		left: $block-spacing * 2;
	}

	&__check {
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
	}

	&__unchecked,
	&__checked {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: $block-spacing;

		i {
			font-size: 24px;
			color: $border-color;
		}
	}

	&__checked {
		display: none;
		color: $brand-color;

		i {
			color: $brand-color;
		}
	}

	&--small {
		min-height: unset;

		.choice__replacement {
			padding: $block-spacing * 2;
		}

		.choice__bottom {
			align-items: center;
			position: unset;
			left: auto;
			right: auto;
			bottom: auto;
		}

		.choice__name {
			width: auto;
			margin-bottom: 0;
		}

		.choice__check {
			width: auto;
		}
	}

	&__input {
		display: none;

		&:checked + .choice__replacement {
			border-color: $brand-color;

			.choice__unchecked {
				display: none;
			}

			.choice__checked {
				display: flex;
			}
		}
	}

	@include responsive(('desktop')) {
		width: calc(33.33% - #{$block-spacing * 2});
	}

	@include responsive(('tablet')) {
		width: calc(33.33% - #{$block-spacing * 2});
	}

	@include responsive(('mobile-landscape')) {
		width: calc(50% - #{$block-spacing * 2});
	}

	@include responsive(('mobile')) {
		width: calc(100% - #{$block-spacing * 2});
		flex: 1 1 auto;
	}
}
