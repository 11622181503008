.section {
	display: flex;
	flex-wrap: wrap;
	gap: $block-spacing * 4;

	&__item {
		display: flex;
		flex-flow: column wrap;
		gap: $block-spacing * 2;
		flex: 1 1;
		padding: $block-spacing * 4;
	}

	&__number {
		color: $brand-color;
		font-size: 32px;
		font-family: $secondary-font-family;
		background-color: $background-color-6;
		height: 48px;
		width: 48px;
		line-height: 38px;
		text-align: center;
		border-radius: 48px;
	}

	&__quote {
		display: flex;
		flex-wrap: wrap;
		gap: $block-spacing * 4;
		align-items: flex-start;
		padding: $block-spacing * 4 25% $block-spacing * 8 120px;
		background-size: contain;
		background-position: bottom right;
		background-repeat: no-repeat;
		position: relative;
		min-height: 320px;

		&-icon {
			display: block;
			font-size: 112px;
			color: $font-color;
			position: absolute;
			top: $block-spacing * 4;
			left: $block-spacing * 4;
			pointer-events: none;
			font-family: $primary-font-family;
		}
	}

	&__author {
		color: $brand-color;
		font-size: 16px;
		font-weight: 700;
		width: 100%;
		text-align: right;
		padding-right: $block-spacing * 4;
	}

	&--margin-top-4x {
		margin-top: $block-spacing * 4;
	}

	&--category {
		padding-top: $vertical-spacing / 2;
		padding-bottom: 0;

		.section__quote {
			padding-bottom: calc(#{$vertical-spacing / 2} + #{$block-spacing * 4});
			background-size: 25%;
		}

		@include responsive(('mobile', 'mobile-landscape')) {
			.section__quote {
				background-size: 269px;
				padding-bottom: 269px;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		padding-top: $block-spacing * 4;

		&__item {
			padding: 0 $block-spacing * 4;
			width: 100%;
			flex: auto;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&--product {
			padding-bottom: $block-spacing * 4;
		}

		&__author {
			padding-right: 0;
		}

		&__quote {
			padding: $block-spacing * 8 $block-spacing * 4 calc(278px + #{$block-spacing * 4});
			background-size: 200px;

			.button {
				flex-grow: 1;
			}

			&-icon {
				top: 0;
			}
		}
	}
}
