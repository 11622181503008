.review-bar {
	background-color: $white;
	padding: $block-spacing * 2 $block-spacing * 3;
	border: 1px solid $border-color;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;
	color: $font-color;
	font-family: $primary-font-family;
	border-radius: 16px;
	font-size: 12px;
	line-height: normal;
	font-weight: 700;
	margin: $block-spacing * 3 0;
	gap: $block-spacing;

	a {
		font-weight: 400;
	}

	&__score {
		width: 30px;
		height: 30px;
		background-image: url('/_zorgtotaal/img/icons/kiyoh.png');
		background-repeat: no-repeat;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 8px;
		margin-right: $block-spacing;
		background-size: cover;
		font-weight: 900;
	}

	&__left {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		padding: $block-spacing * 4;
		gap: $block-spacing * 4;
		justify-content: center;

		&__score {
			margin-right: $block-spacing * 4;
			flex-shrink: 0;
		}

		&__left {
			font-weight: 900;
			flex-flow: row nowrap;
		}

		&__right {
			width: 100%;
			font-weight: normal;
			text-align: center;
		}
	}
}
