.product-options {
	color: $font-color;
	line-height: 1.5em;
	margin-top: $block-spacing * 2;
	margin-bottom: $block-spacing * 4;
	user-select: none;

	&__select {
		font-size: 14px;
		padding: $block-spacing * 2;
		cursor: pointer;
		border-bottom: 1px solid $border-color;
		border-radius: 0;
		background-color: $white;
		user-select: none;
	}

	&__replacement {
		display: flex;
		flex-flow: row wrap;
		padding: $block-spacing * 2;
		background-color: $white;
		cursor: pointer;
		border-bottom: 1px solid $border-color;
	}

	&__image {
		width: 65px;
		height: 65px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	&__right {
		padding-left: $block-spacing * 4;
		flex: 1 1 auto;
		width: calc(100% - 65px);
	}

	&__choice {
		color: $white;
		background-color: $brand-color;
		border-radius: $block-spacing;
		font-size: 14px;
		font-weight: 700;
		display: inline-block;
		padding: $block-spacing / 2 $block-spacing;
		margin: $block-spacing 0;

		i {
			margin-right: $block-spacing / 2;
		}
	}

	&__top {
		padding-bottom: $block-spacing;
	}

	&__content {
		font-size: 14px;
		color: $font-color-2;
		line-height: 24px;
	}

	&__bottom {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
	}

	&__price {
		font-size: 14px;
		font-weight: 700;
		color: $font-color;
	}

	&__check {
		font-size: 14px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-end;
	}

	&__unchecked,
	&__checked {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: $block-spacing;

		i {
			font-size: 24px;
		}
	}

	&__unchecked {
		color: $font-color;

		i {
			color: $border-color;
		}
	}

	&__checked {
		display: none;
		color: $brand-color;

		i {
			color: $brand-color;
		}
	}

	&__input {
		display: none;

		&:checked + .product-options__replacement {
			background-color: $background-color-6;

			.product-options__top {
				strong {
					color: $brand-color;
				}
			}

			.product-options__unchecked {
				display: none;
			}

			.product-options__checked {
				display: flex;
			}
		}
	}

	&__indicator-container {
		margin-bottom: $block-spacing;
	}

	.accordion {
		border: 1px solid $border-color;
		border-radius: 24px;
		overflow: hidden;
	}

	.accordion__icon-open,
	.accordion__icon-close {
		right: $block-spacing * 2;
		font-size: 16px;
		color: $font-color;
	}

	.accordion--hidden {
		.product-options__select {
			border: 0;
		}
	}
}
