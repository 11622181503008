@mixin field {
	display: flex;
	width: 100%;

	.mdc-floating-label {
		font-family: $primary-font-family;
	}

	&--focused#{&}--outlined:not(&--disabled) .mdc-notched-outline {
		&__leading,
		&__notch,
		&__trailing {
			border-width: 2px;
		}
	}
}

@mixin mdc-field($color: $font-color, $hover-color: $hover-color, $focused-color: $active-color, $border-radius: 0) {
	@at-root .mdc-select {
		@include field;

		@include mdc-select-shape-radius($border-radius);

		@include mdc-select-label-color($color);
		@include mdc-select-focused-label-color($focused-color);

		@include mdc-select-outline-color($color);
		@include mdc-select-hover-outline-color($hover-color);
		@include mdc-select-focused-outline-color($focused-color);

		@include mdc-select-focused-bottom-line-color($focused-color);

		.mdc-notched-outline {
			z-index: 1;
		}

		.mdc-line-ripple--active {
			transform: scaleX(0);
		}

		&__dropdown-icon {
			@include mdc-select-dd-arrow-svg-bg_($color, 1);
		}

		&--focused &__dropdown-icon {
			@include mdc-select-dd-arrow-svg-bg_($focused-color, 1);
		}
	}

	@at-root .mdc-text-field {
		@include field;

		@include mdc-text-field-shape-radius($border-radius);

		@include mdc-text-field-label-color($color);

		&--focused {
			.mdc-floating-label {
				color: $focused-color !important;
			}
		}

		@include mdc-text-field-outline-color($color);
		@include mdc-text-field-hover-outline-color($hover-color);
		@include mdc-text-field-focused-outline-color($focused-color);
		@include mdc-text-field-fill-color($white);

		textarea {
			resize: vertical;
			height: 188px !important;
		}
	}
}

.mdc-text-field:not(.mdc-text-field--disabled) {
	background-color: $white;
	border-radius: 8px;
}

.mdc-select--outlined:not(.mdc-select--disabled) {
	background-color: $white;
	border-radius: 8px;
}

.mdc-notched-outline__leading {
	// background-color: $white;
	border-color: $border-color !important;
	width: 8px;
	border-radius: 8px 0 0 8px !important;
}

.mdc-notched-outline__notch {
	// background-color: $white;
	border-color: $border-color !important;
}

.mdc-notched-outline__trailing {
	// background-color: $white;
	border-color: $border-color !important;
	width: 8px;
	border-radius: 0 8px 8px 0 !important;
}

input:focus + .mdc-notched-outline {
	.mdc-notched-outline__leading,
	.mdc-notched-outline__notch,
	.mdc-notched-outline__trailing {
		border-color: $brand-color !important;
	}
}
