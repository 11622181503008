.product-sections {
	border: 1px solid $border-color;
	border-radius: 16px;
	overflow: hidden;

	&__content,
	&__image {
		width: 50%;
		flex: 0 1 auto;
	}

	&__content {
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;
		text-align: left;
		gap: $block-spacing * 2;
		padding: $block-spacing * 4 8%;

		&--single {
			width: 100%;
		}
	}

	&__container {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		border-bottom: 1px solid $border-color;

		&:nth-child(even) {
			.product-sections__content {
				order: 2;
			}
		}

		&:last-child {
			border-bottom: 0;
		}
	}

	&__image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		min-height: 400px;
		position: relative;

		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&__content {
			order: 2;
		}

		&__image {
			order: 1;
			background-size: cover;
			min-height: unset;

			&::after {
				display: block;
				content: ' ';
				padding-bottom: 55%;
			}
		}

		&__content,
		&__image {
			width: 100%;
		}
	}
}
