@mixin message {
	@at-root .message {
		width: 100%;
		max-width: calc(#{$grid-width} - 16px);
		margin: $block-spacing * 2 auto;
		position: relative;
		display: none;
		line-height: 1.5em;
		padding: $block-spacing * 2;
		border-radius: $block-spacing * 2;

		&--error {
			color: $white;
			border: 1px solid $error-color;
			background-color: $error-color;
		}

		&--success {
			color: $white;
			border: 1px solid $success-color;
			background-color: $success-color;
		}

		&--visible {
			display: block;
		}

		i {
			margin-right: $block-spacing;
		}

		a {
			text-decoration: underline;
		}

		@content;
	}
}
