@include product-info {
	padding: 0;

	&__price {
		display: flex;
		flex-flow: row wrap;
		margin-bottom: $block-spacing * 2;

		&__highlight {
			color: $font-color;
			font-size: 24px;
			margin: 0;
		}

		&__advice {
			text-decoration: line-through;
			font-size: 12px;
			color: $font-color-3;
			margin-right: $block-spacing;

			&--hidden {
				display: none;
			}
		}

		&__diff {
			&--container {
				&-hidden {
					display: none;
				}
			}
		}
	}

	&__option {
		margin-top: 0;
		margin-bottom: $block-spacing;
		color: $black;
		font-size: 14px;
		font-weight: bold;
	}

	&__tags {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: $block-spacing * 2;
	}

	&__tag {
		margin-right: 4px;
		background-color: $black;
		color: $white;
		font-size: 14px;
		font-weight: bold;
		text-transform: lowercase;
		padding: $block-spacing $block-spacing * 2;
	}

	&__message {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: $block-spacing;
		padding: $block-spacing;
		color: $font-color;
		font-family: $primary-font-family;
		font-size: 12px;
		line-height: normal;
		background-color: $warning-color;
		font-weight: 700;
		margin-bottom: $block-spacing * 2;

		i {
			font-size: 24px;
		}

		&--hidden {
			display: none;
		}
	}

	&__container {
		display: flex;
		flex-flow: row wrap;
		gap: $block-spacing * 2;

		.button {
			flex: 1 1 auto;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;

			&--hidden {
				display: none;
			}
		}

		&--spacing-bottom-4x {
			margin-bottom: $block-spacing * 4;
		}
	}
}

@include responsive(('desktop', 'desktop-hd')) {
	.product-info-extended {
		padding-left: 8%;
	}
}
