@include media {
	&__caption {
		width: 100%;

		&--dark {
			color: $black;
		}

		&--bottom {
			top: auto;
			bottom: 0;
		}
	}

	&__title {
		font-size: 40px;
		font-weight: 700;
		line-height: normal;
		letter-spacing: -0.8px;
		font-family: $secondary-font-family;
		width: 83.3333%;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			font-size: 24px;
		}
	}

	&__banner {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		&::after {
			display: block;
			content: ' ';
			padding-bottom: 90%;
		}
	}

	&__icon {
		margin-bottom: $block-spacing * 4;
	}

	&__content {
		padding: $block-spacing * 4;
		border: 1px solid $border-color;
		border-radius: 0 0 16px 16px;
		position: relative;
		padding-bottom: calc(49px + #{$block-spacing * 7});
		flex: 1 1;
		height: 100%;

		.button {
			position: absolute;
			bottom: $block-spacing * 4;
			left: $block-spacing * 4;
		}
	}

	&--flex {
		display: flex;
		flex-direction: column;
	}

	&--h-full {
		height: 100%;
	}

	&--mh-full {
		min-height: 100%;
	}

	&--hero {
		background-position: center right;
		height: calc(100vh - 98px);
		min-height: 830px;
	}

	&--hero-landscape {
		background-position: center right;
		height: 500px;
	}

	&--home {
		z-index: 1;
	}

	&--product {
		width: 100%;
		height: auto;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 100%;
		}

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 100%;
			max-height: 100%;
			height: auto;
			width: auto;
			transform: translate(-50%, -50%);
		}
	}

	// &--video {
	// 	user-select: none;
	// 	pointer-events: none;
	// }

	&--80 {
		min-height: 100%;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 80%;
		}
	}

	&--90 {
		min-height: 100%;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 90%;
		}
	}

	&--brochure {
		min-height: 464px;
		margin-bottom: 30px;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			min-height: auto;
			margin: 0;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 70%;
			}
		}
	}

	&--showroom-banner {
		min-height: 622px;
		background-position: center left;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			min-height: auto;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 80%;
			}
		}
	}

	&--border {
		border: 1px solid $border-color;
	}

	&--border-radius {
		border-radius: 16px;
	}

	&--border-radius-top {
		border-radius: 16px 16px 0 0;
		overflow: hidden;
	}

	&--border-radius-bottom-right {
		border-radius: 0 0 32px;
	}

	&--120 {
		min-height: 100%;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 120%;
		}
	}

	&--145 {
		min-height: 100%;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 145%;
		}
	}

	&--shop {
		height: calc(100% - 67px);

		@include responsive(('tablet')) {
			height: calc(100% - 24px);
		}

		@include responsive(('mobile', 'mobile-landscape')) {
			height: auto;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 100%;
			}
		}
	}

	&--brand {
		position: absolute;
		top: calc(50% - #{$vertical-spacing / 2});
		right: $block-spacing;
		transform: translateY(-50%);
		width: calc(41.6666% - #{$block-spacing * 4});

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 60%;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			top: auto;
			transform: none;
			bottom: auto;
			right: auto;
			position: static;
			order: 1;
			width: 100%;

			&::after {
				padding-bottom: 92%;
			}
		}
	}

	&__buttons {
		@extend %flex-row;

		margin: 0 $block-spacing * 3;
		max-width: 600px;
		padding-top: $block-spacing;
		width: 83.3333%;

		.button {
			width: calc(50% - #{$block-spacing * 2});
			margin: $block-spacing;
		}

		&--center {
			justify-content: center;
		}

		@include responsive(('mobile')) {
			width: 100%;
			margin: 0;
			padding: 5% $block-spacing 0;
		}
	}

	&__mobile {
		display: none;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&__desktop {
			display: none;
		}

		&__mobile {
			display: block;
		}
	}
}
