.payment-icons {
	@extend %flex-row;

	align-items: center;
	justify-content: space-between;
	flex: 1;
	gap: $block-spacing;

	&__icon {
		max-height: 20px;
	}
}
