.compare-page {
	&__sidebar {
		display: flex;
		flex-flow: column wrap;
		height: 100%;
		justify-content: space-between;

		.text {
			padding: $block-spacing;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			.table {
				display: none;
			}

			.text {
				display: none;
			}

			.sidebar .text {
				display: block;
			}
		}
	}

	.carousel__slide,
	.grid__col {
		padding-left: 0;
		padding-right: 0;
	}

	&__product {
		height: 100%;
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;

		.product {
			height: auto;
			flex: 1 1 auto;
			width: calc(100% - #{$block-spacing * 2});
			margin: 0 $block-spacing;
		}

		.text {
			padding: $block-spacing $block-spacing * 2;

			@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
				visibility: visible;
			}
		}

		.table {
			.table__td {
				padding: $block-spacing $block-spacing * 2;
				width: 50%;

				&:first-child {
					display: none;

					@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
						display: table-cell;
					}
				}
			}
		}
	}

	.buttons {
		padding: 0 $block-spacing;
	}
}
